import { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { initialSearchFilters } from '../utils/Constants';
import { useQuery } from '../utils/formHelper';
import {
  getSearchFilters,
  getQueryParam,
  getSector,
  getSectorKeys,
  isQueryParam,
  isSectorParam,
  isSortParam,
  isTypesParam,
  isArticleTypesParam,
  getArticleTypeKey,
  getArtitleTypeFilter,
  isStatesIdParam,
  getStatesIdsFilter,
  getStatesId,
  getTypes,
  isPriceParam,
  getActivityTypes,
  isActivityTypesParam,
  getActivityTypeFilter,
  isLocationParam,
  getLocations,
} from '../utils/utils';

const SearchFiltersContext = createContext({});

export function SearchFiltersProvider({ children }) {
  const history = useHistory();
  let paramsURL = useQuery();
  const queryParam = paramsURL.get('query');
  const sectorKeyParam = paramsURL.get('sector');
  const priceParam = paramsURL.get('price');
  const locationParam = paramsURL.get('location');
  const sortParam = paramsURL.get('sort');
  const typeParam = paramsURL.get('type');
  const articleTypeParam = paramsURL.get('article_type');
  const statesIdParam = paramsURL.get('id_state');
  const activityTypeParam = paramsURL.get('activity_filter');
  let activityKeys = getActivityTypes(activityTypeParam);
  let sectorKeys = getSectorKeys(sectorKeyParam);
  let typeKey = getTypes(typeParam);
  let articleTypeKeys = getArticleTypeKey(articleTypeParam);
  let statesId = getStatesId(statesIdParam);
  let location = getLocations(locationParam);

  const remplaceRouteParameter = (param, valueParam) => {
    const queryUrl = isQueryParam(param)
      ? valueParam
      : getQueryParam(queryParam);

    const sectorUrl = isSectorParam(param)
      ? getSector(valueParam)
      : getSector(sectorKeyParam);

    const priceUrl = isPriceParam(param) ? valueParam : priceParam;

    const typeUrl = isTypesParam(param) ? valueParam : typeParam;
    const locationURL = isLocationParam(param) ? valueParam : locationParam;

    const articleTypeUrl = isArticleTypesParam(param)
      ? getArtitleTypeFilter(valueParam)
      : getArtitleTypeFilter(articleTypeParam);

    const activityTypeUrl = isActivityTypesParam(param)
      ? getActivityTypeFilter(valueParam)
      : getArtitleTypeFilter(activityTypeParam);

    const statesIdUrl = isStatesIdParam(param)
      ? getStatesIdsFilter(valueParam)
      : getStatesIdsFilter(statesId);

    const sortParamUrl = isSortParam(param) ? valueParam : sortParam;

    history.replace(
      `/resultados-busqueda/${getSearchFilters(
        queryUrl,
        sectorUrl,
        sortParamUrl,
        typeUrl,
        activityTypeUrl,
        articleTypeUrl,
        statesIdUrl,
        priceUrl,
        locationURL
      )}`
    );
  };

  const clearUrlParameters = () => {
    const queryUrl = getQueryParam(queryParam);
    history.replace(`/resultados-busqueda/${getSearchFilters(queryUrl)}`);
  };
  const [searchFilters, setSearchFilters] = useState(
    structuredClone(initialSearchFilters)
  );
  const [hasSearchFilterChanged, setHasSearchFilterChanged] = useState(false);
  const [hasSearchPriceFilterChanged, setHasSearchPriceFilterChanged] =
    useState(false);

  return (
    <SearchFiltersContext.Provider
      value={{
        searchFilters,
        setSearchFilters,
        hasSearchFilterChanged,
        setHasSearchFilterChanged,
        hasSearchPriceFilterChanged,
        setHasSearchPriceFilterChanged,
        queryParam,
        sectorKeyParam,
        sortParam,
        sectorKeys,
        activityKeys,
        priceParam,
        typeKey,
        articleTypeKeys,
        statesId,
        location,
        locationParam,
        remplaceRouteParameter,
        clearUrlParameters,
      }}
    >
      {children}
    </SearchFiltersContext.Provider>
  );
}

export default SearchFiltersContext;
