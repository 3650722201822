import makeStyles from '@mui/styles/makeStyles';

export const ActivitiesStyles = makeStyles((theme) => ({
  activitySectionBlock: {
    backgroundColor: theme.palette.primary.white,
    padding: theme.spacing(4),
    margin: 0,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1200,
      padding: 0,
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(4, 2),
    },
  },
  marker: {
    width: '14px',
    height: '14px',
    borderRadius: '100%',
    minWidth: '6px',
  },
  markerShows: {
    backgroundColor: 'rgba(28, 107, 250, 1)',
  },
  markerNovelty: {
    backgroundColor: 'rgba(252, 89, 69, 1)',
  },
  activityRelatedBlock: {
    backgroundColor: theme.palette.primary.white,
    padding: theme.spacing(5.5, 3.25, 0, 2.75),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5.25, 3.25, 0, 2),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8.375, 3.25, 0, 3.25),
    },
  },
  activitiesFiltersContainer: {
    backgroundColor: theme.palette.primary.dark,
  },
  activityTabPanel: {
    backgroundColor: theme.palette.primary.white,
    '& .MuiBox-root': {
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(2),
      },
    },
  },
  otherActivitiesDivider: {
    border: '1px solid #8B8B8B',
    margin: theme.spacing(1.375, 0, 4, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1, 0, 4.5, 0),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(1, 0, 4.875, 0),
    },
  },
  activityRelatedMargin: {
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
      width: '100%',
    },
  },
  cardContainerPerfil: {
    backgroundColor: theme.palette.primary.light,
    '& p': {
      textAlign: 'center',
    },
  },
  cardContainer: {
    backgroundColor: 'transparent',
    boxShadow: (props) =>
      props.hasShadow ? '1px 3px 6px rgba(0, 0, 0, 0.15)' : 'none',
    marginBottom: theme.spacing(0),
  },
  itemsMainContainer: {
    columnBreakInside: 'avoid',
    display: 'inline-block',
    width: '100%',
  },
  containerRoot: {
    padding: 0,
  },
  cardMainContainer: {
    padding: theme.spacing(0, 2, 1, 2),
    backgroundColor: 'white',
  },
  cardMainContainerHome: {
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 0),
  },
  image: {
    width: '100%',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  imageHome: {
    margin: theme.spacing(1, 0),
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  itemContainer: {
    display: 'flex',
    color: theme.palette.common.black,
    width: '100%',
  },
  itemContainerChips: {
    display: 'flex',
    width: '100%',
  },
  itemTitle: {
    justifyContent: 'start !important',
    margin: 0,
  },
  itemTitleText: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '1.2',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  description: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 12,
    fontWeight: 400,
    '& p': {
      margin: theme.spacing(1, 0, 2, 0),
    },
  },
  tabTitle: {
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'capitalize',
  },
  selectedTitle: {
    color: (props) =>
      props?.idDomain
        ? `${props?.domainColor || '#6b6b6b'} !important`
        : '#E3313D !important',
  },
  flexContainerTabs: {
    backgroundColor: theme.palette.primary.white,
  },
  eventDate: {
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    '& div': {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        fontSize: 14,
        fontWeight: 600,
        margin: 0,
        marginLeft: theme.spacing(1),
      },
    },
  },
  eventDateHome: {
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 0, 1, 0),
    '& div': {
      '& p': {
        color: '#D01E2A',
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
  footerCardDateText: {
    color: theme.palette.primary.main,
  },
  footerCardDateTextDefault: {
    color: theme.palette.dark.main,
  },
  shareIcon: {
    background: 'white',
    padding: theme.spacing(0.5),
  },
  notesDisplayContent: {
    backgroundColor: 'white',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2, 4, 2),
    },
  },
  myMasonryGrid: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% + 24px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'clamp(290px, 100%, 360px)',
      margin: 'auto',
    },
  },
  myMasonryGridInner: {
    padding: theme.spacing(0, 3, 3, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 3, 0),
    },
    [theme.breakpoints.down('md')]: {
      width: 'clamp(290px, 100%, 360px)',
      margin: 'auto',
    },
  },
  filterTypeContainer: {
    borderBottom: '1px solid',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  liCustomLg: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1, 0),
    },
  },
  formControl: {
    width: '100%',
    '& label': {
      color: 'black !important',
    },
    '& div': {
      '&::before': {
        borderBottom: '1px solid black',
      },
      '&:focus': {
        borderBottom: '1px solid black',
      },
      '& focused': {
        borderBottom: '1px solid red',
      },
      '& svg': {
        color: 'black',
      },
    },
    '& .MuiInput-underline:not(.Mui-error)::after': {
      borderBottom: '1px solid black',
    },
    '& .MuiSelect-underline:after': {
      borderBottom: '1px solid black',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  styleShow: {
    backgroundColor: theme.palette.activities.show,
  },
  styleMeeting: {
    backgroundColor: theme.palette.activities.meeting,
  },
  styleEvent: {
    padding: `${theme.spacing(4, 5)} !important`,
  },
  itemType: {
    display: 'flex',
    alignItems: 'center',
  },
  itemTypeTextEvent: {
    fontSize: '18px !important',
  },
  itemTypeText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 700,
    marginLeft: theme.spacing(1),
    textTransform: 'capitalize',
  },
  itemTypeTextHome: {
    fontWeight: 500,
  },
  arrowIcon: {
    maxWidth: 24,
  },
  activitiesFilterContainer: {
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
  },
  activitiesFilter: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%',
    '& .MuiList-root': {
      zIndex: 0,
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(0),
      '& .MuiList-root': {
        zIndex: 0,
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
      },
    },
  },
  activitiesTabsContainer: {
    backgroundColor: theme.palette.primary.white,
    width: '100%',
  },
  containerTabs: { padding: theme.spacing(0, 0, 3, 0) },
  activityTabs: {
    backgroundColor: theme.palette.primary.white,
    borderBottom: `0.5px solid ${theme.palette.warning.text}`,
    marginTop: theme.spacing(0),
  },
  collapse: {
    borderRadius: '0px 0px 5px 5px',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.25)',
    paddingBottom: theme.spacing(1),
    width: '100%',
  },
  collapseTitle: {
    paddingLeft: theme.spacing(2),
  },
  collapseHeader: {
    backgroundColor: theme.palette.dark.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.white,
    cursor: 'pointer',
    height: 47,
  },
  collapseText: {
    '& span': {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  menuActivityContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  activitiesListDialog: {
    minHeight: '80vh',
  },
  otherActivities: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
  },
  flexSpaceBetWeen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  otherActivitiesTitle: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
  },
  descriptionContainer: {
    backgroundColor: '#fff',
    paddingBlock: (props) => (props?.isAgenda ? 0 : theme.spacing(2)),
    fontSize: 24,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      '& > div.MuiContainer-root': {
        padding: theme.spacing(0),
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: (props) => (props?.isAgenda ? 'column' : 'row'),
      '& > div.MuiContainer-root': {
        padding: 0,
      },
    },
  },
  notesContainer: {
    backgroundColor: '#fff',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontSize: 24,
  },
  notesTitle: {
    paddingTop: theme.spacing(0),
    fontSize: 24,
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
  descriptionText: {
    fontSize: 24,
    marginBottom: 16,
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterContainerVertical: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    width: '100%',
  },
  seeMoreButton: {
    '&:hover': {
      color: theme.palette.secondaryAccent.light,
    },
    border: `1px solid ${theme.palette.secondaryAccent.main}`,
    borderRadius: 30,
    color: theme.palette.secondaryAccent.main,
    padding: theme.spacing(0.375, 2.5),
    margin: theme.spacing(0),
    whiteSpace: 'nowrap',
    '& span': {
      fontSize: 13,
      fontWeight: 600,
    },
  },
  calendarContainer: {
    marginTop: theme.spacing(3.75),
    '& .MuiPickerStaticWrapper-root': {
      backgroundColor: '#333333',
    },
    '& .MuiCalendarPicker-root': {
      backgroundColor: '#333333',
      '& div[role=presentation]': {
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '24px',
        textTransform: 'capitalize',
      },
      '& .MuiSvgIcon-root': {
        color: '#a5a5a5',
      },
      '& span': {
        color: '#a5a5a5',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '20px',
      },
    },
  },
  agendaFiltersWrapper: {
    color: '#ffffff',
    margin: '0 auto',
    width: '100%',
    maxWidth: 280,
  },
  filterLabelFilter: {
    color: '#262626',
    fontWeight: 600,
    fontSize: 16,
  },
}));
