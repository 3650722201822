import { Chip, Container, Typography } from '@mui/material';
import classnames from 'classnames';
import { ActivitiesStyles } from './ActivitiesStyles';
import { ImageLazy } from '../Imagelazy/ImageLazy';
import { IMAGE_SIZE } from '../../utils/Constants';
import ArtistTags from '../Artist/ArtistTags';
import ExpandableText from '../ExpandableText/ExpandableText';
import CardHeader from '../Search/CardHeader';

const customTag = 'chipColorWhiteBlackBorder';

export const ActivityBody = ({
  activity,
  isHome,
  type,
  isNovelty,
  inAgendaStrip,
  inAgendaStripDomain,
  inGeneralSearch,
  getDescriptionSectors,
}) => {
  const classes = ActivitiesStyles();
  const srcVersions =
    activity?.card_image?.versions ||
    activity?.cover?.versions ||
    activity?.image?.versions;

  return (
    <>
      <div
        className={isHome ? classes.imageHome : undefined}
        style={{ backgroundColor: inAgendaStrip ? '#ffffff' : 'transparent' }}
      >
        <ImageLazy
          sizePreferer={
            activity?.card_image?.versions || activity?.cover?.versions
              ? IMAGE_SIZE.ORIGINAL
              : IMAGE_SIZE.RESPONSIVE
          }
          srcVersions={srcVersions}
          alt={
            activity?.card_image?.alt_text ||
            activity?.cover?.alt_text ||
            activity?.image?.alt_text ||
            '.'
          }
          classes={classnames(classes.image, isHome && classes.imageHome)}
          width='264'
          height='104'
          float='none'
          sx={{ borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}
          inAgendaStrip={inAgendaStrip}
        />
      </div>
      <div
        className={classnames(
          classes.cardMainContainer,
          isHome && classes.cardMainContainerHome
        )}
      >
        <CardHeader
          type={activity?.data_type ? activity?.data_type : type}
          sector={activity?.sector_label}
          typeService={activity?.type_service}
          typeActivity={activity?.type_activity}
        />

        <div className={classnames(classes.itemContainer, classes.itemTitle)}>
          <Typography
            component={'span'}
            classes={{ body1: classes.itemTitleText }}
          >
            {activity.title}
          </Typography>
        </div>
        <div className={classes.itemContainer}>
          <ExpandableText
            content={activity?.description || activity?.card_description || ''}
            classes={classes.description}
            useExpand={false}
          />
        </div>
        {activity?.sectors?.length > 0 && !isHome && !isNovelty && (
          <div className={classes.itemContainerChips}>
            <ArtistTags
              tags={getDescriptionSectors(activity?.sectors)}
              customTag={customTag}
            />
          </div>
        )}
        {activity?.cycle && (
          <Container disableGutters sx={{ marginBottom: '15px' }}>
            <Chip
              label={activity?.cycle?.title}
              sx={{
                fontSize: 10,
                fontWeight: 600,
                color: '#535353',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: 'calc(100% - 26px)',
                backgroundColor: ' #EBEBED',
                height: 20,
                '& .MuiChip-label': {
                  pl: 1.25,
                  borderRadius: 14,
                  cursor: 'pointer',
                },
              }}
            />
          </Container>
        )}
        {inGeneralSearch && activity?.related_domain?.name && (
          <Container disableGutters sx={{ marginBottom: '15px' }}>
            <Chip
              label={activity?.related_domain?.name}
              sx={{
                fontSize: 10,
                fontWeight: 600,
                color: '#535353',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: 'calc(100% - 26px)',
                backgroundColor: ' #EBEBED',
                height: 20,
                '& .MuiChip-label': {
                  pl: 1.25,
                  borderRadius: 14,
                  cursor: 'pointer',
                },
              }}
            />
          </Container>
        )}
        {(inGeneralSearch || inAgendaStripDomain) &&
          activity?.related_domain?.related_node?.name && (
            <Container disableGutters sx={{ marginBottom: '15px' }}>
              <Chip
                label={activity?.related_domain?.related_node?.name}
                sx={{
                  fontSize: 10,
                  fontWeight: 600,
                  color: '#535353',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: 'calc(100% - 26px)',
                  backgroundColor: ' #EBEBED',
                  height: 20,
                  '& .MuiChip-label': {
                    pl: 1.25,
                    borderRadius: 14,
                    cursor: 'pointer',
                  },
                }}
              />
            </Container>
          )}
      </div>
    </>
  );
};
