import makeStyles from '@mui/styles/makeStyles';

export const PortfolioSectorStyles = makeStyles((theme) => ({
  containerPortfolioBySection: {
    padding: theme.spacing(3.75, 0),
  },
  containerPortfolios: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  containerPortfoliosHome: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 2, 0, 6),
    },
  },
  portfolioListHome: {
    padding: '0px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 0, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0),
    },
  },
  containerTitlePortfolio: { textAlign: 'center', padding: 5 },
  imgCard: {
    borderRadius: '50%',
    boxShadow: '6px 8px 15px 1px rgba(0,0,0,0.20)',
    height: 110,
    width: 110,
  },
  containerSector: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    color: (props) => props?.colors?.dark || theme.palette.secondary.redSector,
    display: 'flex',
    fontWeight: 600,
    paddingBottom: theme.spacing(1.25),
  },
  sectorTitle: {
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  styleSVG: { padding: '1px', stroke: 'black', strokeWidth: '1px' },
  containerArrow: {
    marginTop: theme.spacing(-0.375),
    paddingLeft: theme.spacing(1.875),
  },
  cardWrapper: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1.75, 0, 2, 0),
    textAlign: 'center',
    textDecoration: 'none',
    width: 170,
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
  },
  card: {
    margin: 'auto',
    width: 110,
  },
  title: {
    color: theme.palette.secondaryAccent.main,
    display: '-webkit-box',
    fontWeight: 700,
    overflow: 'hidden',
    lineHeight: '1.3',
    padding: theme.spacing(1),
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    maxHeight: 70,
    maxWidth: 160,
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  location: {
    color: theme.palette.secondaryAccent.main,
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    width: 'auto',
  },
  pointLocation: {
    backgroundColor: (props) =>
      props?.colors?.dark || theme.palette.secondary.main,
    borderRadius: '100%',
    height: 6,
    minWidth: 6,
    width: 6,
  },
  containerLocation: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 10,
    justifyContent: 'center',
    maxWidth: '75%',
  },
  titlePortfolioBySector: {
    color: theme.palette.secondaryAccent.main,
    fontWeight: 600,
    paddingBottom: theme.spacing(2.125),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3.75),
    },
  },
  titlePortfolioInHome: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(7),
      paddingBottom: theme.spacing(3.75),
    },
  },
  button: {
    alignItems: 'center',
    border: 'none',
    borderRadius: 20,
    color: theme.palette.dark.main,
    display: 'flex',
    fontSize: 13,
    fontWeight: 600,
    height: 30,
    justifyContent: 'center',
    padding: theme.spacing(0, 0.5),
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
    '&:hover': {
      backgroundColor: 'black',
      color: theme.palette.primary.white,
    },
    [theme.breakpoints.up('md')]: {
      border: '1px solid ',
      padding: theme.spacing(0, 2.75),
      textDecoration: 'none',
    },
  },
  containerButton: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 70,
    },
  },
}));
