import { Ability, AbilityBuilder, defineAbility } from '@casl/ability';
import AuthService from '../../services/AuthService';
import { BORoles, SpecialsRole } from '../../utils/Constants';
import { Backoffice_Rules as BORules } from './Rules';

export const setRules = (can, cannot) => {
  //metodo donde se define las acciones por rol
  //tener en cuenta que cuando haya multiples rols por usuarios dejar el rol mas grande ultimo
  const userRoles = AuthService.getCurrentUserRoles();
  if (userRoles.includes(SpecialsRole.SYSTEM)) {
    can(BORules.Actions.ROUNDS_MASS_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
  }
  if (userRoles.includes(BORoles.ADMIN_HOME)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.HOME_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.HOME_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SLIDERS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SLIDERS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SECTOR_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SECTOR_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MICA_TITLES_READ, BORules.Subjects.BACKOFFICE);
  }

  if (userRoles.includes(BORoles.ADMIN_QUERY)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.USER_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.VALIDATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PUBLIC_CONTENT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.HOME_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SLIDERS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EVENT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SECTOR_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.STATISTICS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_ACCESS_TO_ALL, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_LIST_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_LIST_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_READ_EXCEL, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAM_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_ACTION_POINT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MICA_TITLES_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_READ_STATISTICS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_READ_STATISTICS, BORules.Subjects.BACKOFFICE);
  }

  if (userRoles.includes(BORoles.ADMIN_VALIDATOR)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.VALIDATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.VALIDATION_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PUBLIC_CONTENT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MICA_TITLES_READ, BORules.Subjects.BACKOFFICE);
  }

  if (userRoles.includes(BORoles.ADMIN_SHOWS)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.USER_READ, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.EVENT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EVENT_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PUBLIC_CONTENT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EXPORT, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.HOME_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.HOME_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MICA_TITLES_READ, BORules.Subjects.BACKOFFICE);
  }
  if (userRoles.includes(BORoles.ADMIN_RDN)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_MASS_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.USER_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PUBLIC_CONTENT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_VIRTUAL_ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MICA_TITLES_READ, BORules.Subjects.BACKOFFICE);
  }
  if (userRoles.includes(BORoles.ADMIN_FORMS)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_READ_EXCELS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MICA_TITLES_READ, BORules.Subjects.BACKOFFICE);
  }
  if (userRoles.includes(BORoles.MANAGER_DOMAINS)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_READ_STATISTICS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_LIST_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_LIST_READ, BORules.Subjects.BACKOFFICE);
  }
  if (userRoles.includes(BORoles.RESPONSIBLE_DOMAIN)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_ACCESS_TO_ALL, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_READ_STATISTICS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_LIST_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_LIST_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAM_HIGHLIGHT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAM_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAM_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_ACTION_POINT_READ, BORules.Subjects.BACKOFFICE);
    can(
      BORules.Actions.MEETING_ACTION_POINT_HIGHLIGHT,
      BORules.Subjects.BACKOFFICE
    );
    can(BORules.Actions.MEETING_VIRTUAL_ACCESS, BORules.Subjects.BACKOFFICE);
  }
  if (userRoles.includes(BORoles.RESPONSIBLE_NODE)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_READ_EXCEL, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_ACTION_POINT_READ, BORules.Subjects.BACKOFFICE);
    can(
      BORules.Actions.MEETING_ACTION_POINT_WRITE,
      BORules.Subjects.BACKOFFICE
    );
    can(BORules.Actions.MEETING_VIRTUAL_ACCESS, BORules.Subjects.BACKOFFICE);
  }
  if (userRoles.includes(BORoles.ADMIN_MANAGER)) {
    cannot(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_MASS_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.USER_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.USER_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_WRITE, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.VALIDATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.VALIDATION_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PUBLIC_CONTENT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EXPORT, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.HOME_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.HOME_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EVENT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EVENT_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.STATISTICS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SECTOR_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SECTOR_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_READ_EXCELS, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.SLIDERS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SLIDERS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_VIRTUAL_ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MICA_TITLES_READ, BORules.Subjects.BACKOFFICE);
  }

  if (userRoles.includes(BORoles.SUPER_ADMIN)) {
    can(BORules.Actions.ADMIN, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_MASS_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.USER_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.USER_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ROUNDS_WRITE, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.EVENT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EVENT_WRITE, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.VALIDATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.VALIDATION_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PUBLIC_CONTENT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACCESS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.EXPORT, BORules.Subjects.BACKOFFICE);

    can(BORules.Actions.HOME_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.HOME_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.COMMUNICATION_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.CYCLE_EXPORT, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.STATISTICS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SECTOR_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SECTOR_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.FORMS_READ_EXCELS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_ACCESS_TO_ALL, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_READ_STATISTICS, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_LIST_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAMS_LIST_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_LIST_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_LIST_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.PROGRAM_DELETE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_DELETE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.ACTION_POINT_READ_EXCEL, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SLIDERS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.SLIDERS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAMS_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAMS_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAM_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_PROGRAM_WRITE, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MEETING_ACTION_POINT_READ, BORules.Subjects.BACKOFFICE);
    can(BORules.Actions.MICA_TITLES_READ, BORules.Subjects.BACKOFFICE);

    can(
      BORules.Actions.MEETING_ACTION_POINT_WRITE,
      BORules.Subjects.BACKOFFICE
    );
    can(
      BORules.Actions.MEETING_ACTION_POINT_HIGHLIGHT,
      BORules.Subjects.BACKOFFICE
    );
    can(BORules.Actions.MEETING_VIRTUAL_ACCESS, BORules.Subjects.BACKOFFICE);
  }
};

const ability = defineAbility((can, cannot) => {
  //metodo principal donde se settean y definen las reglas. las reglas (rules) son todos los can y cannot definidos
  setRules(can, cannot);
});

export default ability;
export const updateAbility = () => {
  //metodo para actualizar las rules al realizarse un login o logoff
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  setRules(can, cannot);
  ability.update(rules);
};
