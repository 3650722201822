import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, useTheme, useMediaQuery } from '@mui/material';
import AdminPreNavbar from './AdminPreNavbar';
import AuthService from '../services/AuthService';
import LateralMenuNavbar from './LateralMenu/LateralMenuNavbar';
import { IntervalTimes } from '../utils/Constants';
import { NavbarGreeting } from './NavbarGreeting';
import { NavbarStyles } from './NavbarStyles';
import { SearchModal } from './SearchModal';
import { useCurrentUserData } from '../hooks/useCurrentUserData';
import { useInterval } from '../utils/utils';
import MessengerService from '../services/MessengerService';

const Navbar = () => {
  const { isUserLoggedIn } = useCurrentUserData();
  const isLoggedIn = isUserLoggedIn();
  let location = useLocation();
  const menuSectorsAnchorRef = useRef(null);
  const menuCyclesAnchorRef = useRef(null);
  const menuDomainsAnchorRef = useRef(null);
  const classes = NavbarStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const resultPage = '/resultados-busqueda/';
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuSectorsOpen, setMenuSectorsOpen] = useState(false);
  const [menuCyclesOpen, setMenuCyclesOpen] = useState(false);
  const [menuDomainsOpen, setMenuDomainsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(location?.state?.query ?? '');
  const [hasPublishedPortfolio, setHasPublishedPortfolio] = useState(false);
  const [publishedPortfolio, setPublishedPortfolio] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const isLargeWindow = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isResultPageUrl = (path) => {
    return path?.includes(resultPage);
  };
  const isResultPage = isResultPageUrl();
  const [searchOpen, setSearchOpen] = useState(isResultPage ? true : false);
  const { userData, updateUserData, isLoadingTokenUser } = useCurrentUserData();
  const [unread, setUnread] = useState({});
  const checkUnread = () => {
    MessengerService.getUnread().then(({ data }) => {
      setUnread(data);
    });
  };
  //HACK para que se actualice la data del usuario cuando navegue dentro de /tu-mica
  useEffect(() => {
    const isTuMicaUrl = location.pathname.split('/').indexOf('tu-mica') > 0;
    if (isTuMicaUrl) {
      shouldUpdateUserData();
    }
    return () => {
      setMenuOpen(false);
      setMenuSectorsOpen(false);
      setMenuCyclesOpen(false);
      setMenuDomainsOpen(false);
      setSearchQuery(location?.state?.query ?? '');
      setSelectedMenuItem(null);
      setSearchOpen(isResultPage ? true : false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  useEffect(() => {
    shouldUpdateUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldUpdateUserData = () => {
    if (isLoggedIn) updateUserData();
  };

  useEffect(() => {
    if (isLoggedIn) initNavbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, userData]);

  const initNavbar = () => {
    getUserData();
    checkUnread();
  };

  const getUserData = () => {
    setHasPublishedPortfolio(userData.portfolio_published_exist);
    setPublishedPortfolio(userData.portfolio_published);
  };

  useInterval(() => {
    if (isLoggedIn) {
      checkUnread();
    }
  }, IntervalTimes.UNREAD);

  const closeMenu = () => {
    setMenuOpen(false);
    setMenuSectorsOpen(false);
    setMenuCyclesOpen(false);
    setMenuDomainsOpen(false);
    setSelectedMenuItem(null);
  };

  history.listen((location) => {
    closeMenu();
    if (!isResultPageUrl(location.pathname)) {
      setSearchQuery('');
      setSearchOpen(false);
    } else {
      if (location?.state?.query) setSearchQuery(location?.state?.query);
    }
  });

  const logInCloseMenu = () => {
    setMenuOpen(false);
  };

  const isAdmin = AuthService.canAccessToBackoffice();
  const labelNavbar = () => {
    return isLargeWindow ? t('accessibility.navbar.labelInit') : '';
  };

  const handleClose = () => {
    setSearchOpen(false);
  };

  return (
    <section
      className={classes.root}
      aria-label={t('accessibility.navbar.label')}
      id='navbar'
    >
      <div role='presentation' aria-label={labelNavbar()} tabIndex={0}></div>
      {isLoggedIn && isAdmin && (
        <AdminPreNavbar logInCloseMenu={logInCloseMenu} />
      )}

      <AppBar position='static' elevation={0} className={classes.appBar}>
        <Toolbar disableGutters classes={{ root: classes.toolbar }}>
          <LateralMenuNavbar
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            closeMenu={closeMenu}
            isAdmin={isAdmin}
            isMobile={isMobile}
            setMenuSectorsOpen={setMenuSectorsOpen}
            menuSectorsOpen={menuSectorsOpen}
            setMenuCyclesOpen={setMenuCyclesOpen}
            menuCyclesOpen={menuCyclesOpen}
            setMenuDomainsOpen={setMenuDomainsOpen}
            menuDomainsOpen={menuDomainsOpen}
            menuSectorsAnchorRef={menuSectorsAnchorRef}
            menuCyclesAnchorRef={menuCyclesAnchorRef}
            menuDomainsAnchorRef={menuDomainsAnchorRef}
            isLargeWindow={isLargeWindow}
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={setSelectedMenuItem}
            isLoadingTokenUser={isLoadingTokenUser}
          />

          <NavbarGreeting
            isLoggedIn={isLoggedIn}
            isLargeWindow={isLargeWindow}
            classes={classes}
            publishedPortfolio={publishedPortfolio}
            setMenuOpen={setMenuOpen}
            hasPublishedPortfolio={hasPublishedPortfolio}
            userData={userData}
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            isResultPageUrl={isResultPageUrl(location.pathname)}
            unread={unread}
            checkUnread={checkUnread}
          />
        </Toolbar>
      </AppBar>

      <SearchModal
        setSearchQuery={setSearchQuery}
        isMobile={isMobile}
        handleClose={handleClose}
        searchQuery={searchQuery}
        searchOpen={searchOpen}
      />
    </section>
  );
};

export default Navbar;
