import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Z_INDEX } from '../utils/Constants';

export const WIDTH_HOME_SAFEAREA = '1200px'; // '1360px'

export const NavbarStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    width: '100%',
    zIndex: 100,
    '&:focus-visible': {
      // outlineColor: 'white !important',
    },
  },
  addProfileLink: {
    color: theme.palette.primary.white,
    paddingLeft: 75,
    paddingBottom: 40,
    textDecoration: 'underline',
    fontSize: 14,
  },
  searchIconModal: {
    color: 'black',
    padding: 0,
    width: '33px',
    height: '33px',
    cursor: 'pointer',
  },
  searchIconContainerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  appBar: {
    backgroundColor: theme.palette.secondaryAccent.main,
  },
  boxSector: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    padding: 0,
    width: '100%',
    paddingRight: '16px',
  },
  menuButton: {
    borderRadius: 30,
    fontSize: 13,
    fontWeight: 600,
    margin: theme.spacing(0),
    padding: theme.spacing(0.875, 2.5),
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.white,
    },
    [theme.breakpoints.up('md')]: {
      width: 'unset',
    },
  },
  mainMenuItemIcon: {
    color: 'white',
    display: 'flex',
  },
  loginMenuButton: {
    borderRadius: 30,
    fontSize: 13,
    fontWeight: 600,
    margin: theme.spacing(0, 0, 0, 1),
    padding: theme.spacing(0),
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.white,
    },
    [theme.breakpoints.up('md')]: {
      width: 'unset',
    },
  },
  grow: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    zIndex: Z_INDEX.MEDIUM,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: WIDTH_HOME_SAFEAREA,
    width: '100%',
    padding: theme.spacing(0, 2),
    height: 64,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
    },
  },
  searchBottomLine: {
    borderBottom: `1.5px solid black`,
    borderRadius: '0',
  },
  search: {
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
    color: theme.palette.secondaryAccent.almostWhite,
    display: 'flex',
    fontSize: 16,
    padding: theme.spacing(0, 0.5, 0, 1),
    position: 'relative',
    transition: theme.transitions.create('border-color'),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 520,
    },
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
  },
  // blankSpace: {
  //   width: '30vw',
  // },
  searchIcon: {
    width: '40px',
    height: '40px',
    borderRadius: 0,
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  searchIconOnSearch: {
    borderRadius: '100px',
    backgroundColor: 'white !important',
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'white !important',
    },
  },
  searchIconWrapper: {
    padding: 0,
  },
  searchIconAlone: {
    alignItems: 'center',
    display: 'flex',
    width: 36,
    height: 36,
  },
  inputRoot: {
    color: theme.palette.primary.white,
    flexGrow: 1,
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(0, 0, 0.5, 0),
    transition: theme.transitions.create('width'),
    '&::placeholder': {
      color: theme.palette.secondaryAccent.almostWhite,
      opacity: 1,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    zIndex: Z_INDEX.MEDIUM,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  portfolioButtonSpace: {
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
  centerContent: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'stretch',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'center',
      transform: 'none !important',
    },
  },
  menuToggler: {
    color: theme.palette.primary.white,
    height: 64,
    width: 50,
    padding: 0,
    borderRadius: 0,
    '& svg': {
      color: theme.palette.primary.white,
      height: 32,
      width: 32,
    },
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        height: 24,
        width: 24,
      },
    },
  },
  menuPaperAdmin: {
    backgroundColor: theme.palette.secondaryAccent.main,
    boxSizing: 'border-box',
    height: 'calc(100% - 120px)',
    left: '0 !important',
    maxHeight: 'unset',
    maxWidth: 'unset',
    top: '120px !important',
    transform: 'none !important',
    transition: 'none !important',
    width: '100vw',
  },
  menuPaperNotAdmin: {
    backgroundColor: theme.palette.secondaryAccent.main,
    boxSizing: 'border-box',
    height: 'calc(100% - 53px)',
    left: '0 !important',
    maxHeight: 'unset',
    maxWidth: 'unset',
    top: '53px !important',
    transform: 'none !important',
    transition: 'none !important',
    width: '100vw',
  },
  sectorsPaper: {
    backgroundColor: theme.palette.secondaryAccent.main,
    boxSizing: 'border-box',
    left: '0 !important',
    maxHeight: 'unset',
    maxWidth: 'unset',
    top: '56px !important',
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: '100%',
    padding: 0,
    width: '100%',
  },
  expandableMenu: {
    backgroundColor: theme.palette.secondaryAccent.light,
    borderRadius: 0,
    padding: 0,
    '& li': {
      borderBottom: `1px solid ${theme.palette.secondaryAccent.dark}`,
      padding: 0,
      '&:last-child': {
        borderBottom: 'none',
      },
      '& a': {
        display: 'block',
        fontSize: '.85rem',
        padding: theme.spacing(1),
        textAlign: 'center',
        transition: theme.transitions.create('background-color'),
        width: '100%',
        '&:hover': {
          backgroundColor: theme.palette.secondaryAccent.main,
          textDecoration: 'none',
        },
      },
    },
  },
  expandableMainMenu: {
    backgroundColor: theme.palette.secondaryAccent.main,
    padding: 0,
    width: 320,
    '& li': {
      padding: 0,
      '& a': {
        display: 'block',
        transition: theme.transitions.create('background-color'),
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  expandableSubMainMenu: {
    backgroundColor: '#323232',
    padding: 0,
    width: 260,
    '& li': {
      padding: 0,
      '& a': {
        display: 'block',
        transition: theme.transitions.create('background-color'),
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  expandableYourMicaMenu: {
    backgroundColor: theme.palette.secondaryAccent.main,
    padding: 0,
    minWidth: 260,
    '& li': {
      padding: 0,
      '& a': {
        display: 'block',
        transition: theme.transitions.create('background-color'),
        width: '100%',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  boldLink: {
    color: theme.palette.primary.white,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  },
  mainMenuItem: {
    color: theme.palette.primary.white,
    fontWeight: 600,
    margin: theme.spacing(1.875, 0, 1.875, 5),
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1.875, 0, 1.875, 7),
    },
  },
  mainSubMenuMobileItem: {
    color: theme.palette.primary.white,
    fontWeight: 600,
    margin: theme.spacing(1.875, 0, 1.875, 7),
    paddingRight: theme.spacing(4),
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  mainSubMenuItem: {
    color: theme.palette.primary.white,
    fontSize: 14,
    fontWeight: 500,
    margin: theme.spacing(1, 2),
    overflow: 'hidden',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  yourMicaMenuMainItem: {
    color: theme.palette.primary.white,
    fontWeight: 400,
    fontSize: 16,
    margin: theme.spacing(0),
    textDecoration: 'none',
  },
  yourMicaMenuItem: {
    color: theme.palette.primary.white,
    fontWeight: 400,
    fontSize: 14,
    margin: theme.spacing(1.875, 2),
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 'calc(100vw - 100px)',
    textOverflow: 'ellipsis',
  },
  yourMicaUsername: {
    color: theme.palette.primary.white,
    fontWeight: 400,
    fontSize: 14,
    margin: theme.spacing(0),
    textDecoration: 'none',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 'calc(100vw - 100px)',
    textOverflow: 'ellipsis',
  },
  yourMicaMenuBoldItem: {
    color: theme.palette.primary.white,
    fontWeight: 600,
    fontSize: 14,
    margin: theme.spacing(1.875, 2),
    textDecoration: 'none',
  },
  yourMicaMenuDivider: {
    backgroundColor: '#535353',
    height: 2,
    '&.MuiDivider-root': {
      margin: 0,
    },
  },
  mainMenuItemWrapper: {
    padding: 0,
    '&:hover': {
      backgroundColor: '#393A39',
    },
    '&[aria-expanded="true"]': {
      backgroundColor: '#D01E2A',
    },
  },
  mainSubMenuMobileItemWrapper: {
    padding: 0,
    backgroundColor: '#535353',
    '&:hover': {
      backgroundColor: '#646464',
    },
  },
  yourMicaMenuItemWrapper: {
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor: '#393A39',
    },
  },
  yourMicaMenuTopItemWrapper: {
    padding: theme.spacing(0, 2),
    '&:hover': {
      cursor: 'default',
    },
  },
  yourMicaMenuTopInnerItemWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    padding: theme.spacing(2.5, 2),
  },
  mainSubMenuItemWrapper: {
    backgroundColor: '#323232',
    padding: 0,
    '&:hover': {
      backgroundColor: '#535353',
    },
  },
  greetings: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      order: -1,
    },
  },
  bookmarks: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 0),
    },
  },
  menuItems: {
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 10,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  avatar: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      display: 'flex',
      flexGrow: 0.15,
      justifyContent: 'center',
      borderRight: `1px solid ${theme.palette.secondaryAccent.main}`,
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      height: 'auto',
    },
  },
  sectorsSelector: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  sectorsSelectorItem: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      minHeight: 48,
    },
  },
  sectorsMenu: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.dark.light,
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.spacing(4, 2),
    },
  },
  logoutRoot: {
    padding: 0,
  },
  logout: {
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      backgroundColor: theme.palette.secondaryAccent.dark,
      display: 'flex',
      justifyContent: 'center',
      fontSize: '.85rem',
      height: '100%',
      padding: theme.spacing(1),
      width: '100%',
    },
  },
  login: {
    '&:hover': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  cursorDefault: { cursor: 'default' },
  cursorPointer: { cursor: 'pointer' },
  menuLogIn: {
    cursor: 'default',
    padding: 0,
    paddingLeft: theme.spacing(1),
    '&:nth-of-type(1)': {
      marginLeft: theme.spacing(4.5),
    },
  },
  register: {
    backgroundColor: 'transparent',
    border: '1px solid white',
    color: theme.palette.primary.white,
    marginRight: 0,
    '&:hover': {
      color: 'black',
      border: '1px solid white',
      backgroundColor: theme.palette.primary.white,
    },
  },
  newPortfolio: {
    backgroundColor: 'transparent',
    border: '1px solid white',
    color: theme.palette.primary.white,
    marginRight: theme.spacing(2),
    '&:hover': {
      color: 'black',
      border: '1px solid white',
      backgroundColor: theme.palette.primary.white,
    },
  },
  navbarMainContainer: {
    width: '100%',
  },
  searchToggler: {
    width: '40px',
    height: '40px',
    borderRadius: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  searchContainer: {
    backgroundColor: 'transparent !important',
    cursor: 'default',
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    zIndex: Z_INDEX.MINIMUM,
    width: '100%',
  },
  searchPopover: {
    transform: 'none !important',
    top: '64px !important',
    zIndex: Z_INDEX.MINIMUM,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      top: '56px !important',
    },
  },
  searchMenu: {
    backgroundColor: theme.palette.dark.light,
    borderRadius: '0',
    maxWidth: 'unset',
    padding: theme.spacing(2, 0),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0),
    },
  },
  searchMenuAdmin: {
    marginTop: 64,
  },
  searchButton: {
    backgroundColor: theme.palette.primary.white,
    '& span': {
      color: theme.palette.dark.light,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  searchOptionsExpander: {
    border: 'none',
    float: 'left',
    height: '100%',
    transition: theme.transitions.create('border-color'),
    '& span': {
      color: theme.palette.primary.white,
      flexDirection: 'row-reverse',
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  searchHeader: {
    alignItems: 'center',
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'min-content 1fr min-content',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      paddingLeft: theme.spacing(4),
    },
  },
  searchBoxInputExpanded: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '630px',
    maxWidth: 'calc(100% - 50px)',
    background: 'white',
    padding: '20px',
    height: '80px',
    boxShadow: 24,
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      maxWidth: 'calc(100% - 40px)',
    },
  },

  searchInputExpanded: {
    borderLeft: 'none',
    borderRadius: 0,
    borderRight: 'none',
    borderTop: 'none',
    marginLeft: 0,
    width: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  searchFilters: {},
  searchFilterTitle: {
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  searchChip: {
    backgroundColor: 'transparent',
    height: 26,
    border: `1px solid white`,
    color: 'white',
    margin: theme.spacing(0, 1.75, 1.25, 0),
    transition: theme.transitions.create([
      'color',
      'border-color',
      'background-color',
    ]),
    '& svg': {
      color: theme.palette.primary.white,
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: theme.palette.primary.white,
    },
  },
  searchChipSelected: {
    backgroundColor: '#C4C4C4',
    fontWeight: 600,
    color: theme.palette.dark.main,
  },
  mainNavbarContent: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'contents',
    },
  },
  logo: {
    cursor: 'default',
    padding: '0 !important',
  },
  navbarAdmin: {
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    maxWidth: 'unset',
  },
  btnExtraBlack: {
    color: 'black',
    padding: theme.spacing(0.5, 2),
    marginLeft: theme.spacing(2),
    border: '1px solid black',
    borderRadius: '10px',
    textDecoration: 'none',
    fontSize: '14px',
  },
  adminLogOut: {
    color: '#000',
    textDecoration: 'underline',
    marginLeft: 10,
    padding: theme.spacing(1),
  },
  actionsContainer: {
    minWidth: 450,
    textAlign: 'end',
  },
  adminContainer: {
    minWidth: 530,
    textAlign: 'end',
  },
  adminInfoContainer: {
    textAlign: 'center',
  },
  adminShortInfo: {
    fontWeight: 600,
  },
  goToBO: {
    marginTop: theme.spacing(0.625),
  },
  link: {
    color: theme.palette.primary.white,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  menuAndLogo: {
    alignItems: 'center',
    display: 'flex',
    gap: 32,
    zIndex: Z_INDEX.LOWER,
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },
  },
  loginWrapper: {
    display: 'flex',
    gap: 20,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      gap: 5,
      paddingRight: 8,
    },
  },
  logoWrapper: {
    height: '100%',
    zIndex: -1,
  },
  loggedInWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginRight: '15px',
    },
  },
  paperMenu: {
    backgroundColor: '#262626',
    borderRadius: 0,
    overflowY: 'visible',
  },
  addProfileButton: {
    backgroundColor: 'transparent',
    border: '1px solid white',
    borderRadius: 30,
    color: theme.palette.primary.white,
    fontSize: 13,
    fontWeight: 600,
    margin: theme.spacing(0, 2, 0, 0),
    padding: theme.spacing(0.875, 2.5),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
      border: '1px solid white',
      color: 'black',
    },
    [theme.breakpoints.up('md')]: {
      width: 'unset',
    },
  },
}));
