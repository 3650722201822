import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Backoffice,
  BORoles,
  CommonGuestRoles,
  EVENT_TYPE_CLASS,
  ACTIVITY_TYPE_FILTERS,
  urlParameter,
  SESSION_USER_TYPES,
  SpecialsRole,
  TYPE_GROUPER_DATE,
  SLIDER_COLORS,
  SLIDER_COLORS_DEFAULT_EXA,
  SLIDER_COLORS_TEXT,
  SLIDER_COLORS_TEXT_DEFAULT,
  OthersCategoryType,
  SearchEntitiesType,
  BORowsPerPageOptionsStats,
} from './Constants';
import isEqual from 'lodash.isequal';
import { findByProperty } from './formHelper';
import { useCurrentUserData } from '../hooks/useCurrentUserData';
import AuthService from '../services/AuthService';
import EventService from '../services/EventService';
import SearchService from '../services/SearchService';
import useWindowFocus from 'use-window-focus';
import { ROUNDS_INTERESTED_STATES } from './Constants';
import moment from 'moment-timezone';
import ability from '../context/ability/Ability';
import { Backoffice_Rules } from '../context/ability/Rules';
// https://github.com/jaredpalmer/formik
export const DisplayFormikState = (props) => (
  <div style={{ margin: '1rem 0' }}>
    <p style={{ fontFamily: 'monospace' }} />
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '1rem',
        padding: '0.5rem',
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

export const TranslateErrorMessage = (props) => {
  const { t, i18n } = useTranslation();
  const { errorKey, translationErrorsList, variable } = props;
  if (!variable) {
    const statusCode = errorKey?.response?.status || errorKey;
    let errorMessageKey = typeof errorKey === 'string' ? errorKey : 'default';
    let errorMessage = i18n.exists(
      translationErrorsList + '.' + errorMessageKey
    )
      ? t(translationErrorsList + '.' + errorMessageKey)
      : i18n.exists(translationErrorsList + '.default')
      ? t(translationErrorsList + '.default')
      : `${t('error.generic')}: ${statusCode}`;
    return errorMessage;
  } else {
    const statusCode = errorKey?.response?.status || errorKey;
    let errorMessageKey = typeof errorKey === 'string' ? errorKey : 'default';
    let errorMessage = i18n.exists(
      translationErrorsList + '.' + errorMessageKey
    )
      ? t(translationErrorsList + '.' + errorMessageKey, {
          variable: variable,
        })
      : i18n.exists(translationErrorsList + '.default')
      ? t(translationErrorsList + '.default', {
          variable: variable,
        })
      : `${t('error.generic')}: ${statusCode}`;
    return errorMessage;
  }
};

export const removeEmptyElements = (array) => {
  return array.filter(Boolean);
};

export const buildformData = (data) => {
  const formData = new FormData();
  for (var key in data) {
    if (data[key] != null) {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  var k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
export const createFriendlyLink = (id, title) => {
  const cleanTitle = title
    ? title
        .replace(/\s/g, '-')
        .replace(/-+/g, '-')
        .replace(/[^a-å0-9-]/gi, '')
        .toLowerCase()
    : '';
  const friendlyUrl = `${id}-${encodeURIComponent(cleanTitle)}`;
  return friendlyUrl;
};

export const retrieveIdFromURL = (friendlyURL) => {
  const parts = friendlyURL.split('-');
  return parts[0];
};

export const getIdFromFriendlyLink = (friendlyLink) => {
  return friendlyLink.split('-')[0];
};

export const getNameFromFriendlyLink = (friendlyLink) => {
  const cleanTitle = friendlyLink.split('-').slice(1).join(' ');
  return cleanTitle[0].toUpperCase() + cleanTitle.slice(1);
};

export const getProgramName = (programs, idProgram) => {
  if (!programs?.length) return false;
  for (let program of programs) {
    if (program.id_domain === idProgram) {
      return program.name;
    }
  }
  return false;
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const windowFocused = useWindowFocus();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = null;
      if (windowFocused) {
        tick(); //llamo a la funcion cuando vuelve a hacer focus
        id = setInterval(tick, delay);
      } else {
        clearInterval(id);
      }
      return () => clearInterval(id);
    }
  }, [delay, windowFocused]);
};

export const useIntervalWithoutCheckFocus = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export function useDeepEffect(fn, deps) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = prevDeps.current.every((obj, index) =>
      isEqual(obj, deps[index])
    );

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);
}

export const handleKeyUp = (e, handleSubmit) => {
  if (e.key === 'Enter') {
    handleSubmit();
  }
};

export const createMarkup = (label) => {
  return { __html: label };
};

export const scrollToRouteHash = () => {
  const hash = getRouteHash();
  return hash && scrollToElementById(hash);
};

export const getRouteHash = () => {
  return window.location.hash.substring(1);
};

export const scrollToElementById = (idElement) => {
  const elementToScroll = document.getElementById(idElement);
  if (!elementToScroll) return false;
  elementToScroll.scrollIntoView({ behavior: 'smooth' });
};

export const portfolioLocationDescription = (portfolio) => {
  const department = portfolio.department?.description ?? '';
  const state = portfolio.state?.description ?? '';
  const location = department && state ? department + ', ' + state : '';
  return location;
};

export const openPortfolioInNewTab = (id) => {
  window.open(`/usuario/${id}`, '_blank');
};

export const openSectionInNewTab = (id, sectionId) => {
  window.open(`/usuario/${id}/portfolio/seccion/${sectionId}`, '_blank');
};

export const openArticleInNewTab = (id, categoryId) => {
  window.open(`/usuario/${id}/catalogo/articulo/${categoryId}`, '_blank');
};

export const openUserFileInNewTab = (id) => {
  window.open(`/administracion/usuarios/ficha/${id}`, '_blank');
};

export const showPortfolioId = (row) => {
  return (
    row?.user_id ||
    row?.id_user ||
    row?.portfolio?.id_portfolio ||
    row?.user_data?.id_user
  ); //TODO: cambiar cuando cambie el back
};
export const showPortfolioName = (row, collectionType) => {
  return row?.portfolio && row?.portfolio[collectionType]?.project_name;
};

export const filterHistoricValidations = (row) => {
  if (!row?.history) return null;
  const validationsDone = row.history.filter(
    (history) =>
      history['changes']['value_after'] === Backoffice.States.APPROVED ||
      history['changes']['value_after'] === Backoffice.States.REJECTED ||
      history['changes']['value_after'] === Backoffice.States.SUSPENDED ||
      history['changes']['value_after'] === Backoffice.States.PUBLISHED
  );
  return validationsDone;
};

export const showSectors = (sectors, rowSector) => {
  if (!rowSector || rowSector.length === 0) return '';
  const sectorsToShow = sectors.filter(
    (sector) => rowSector.indexOf(sector.key) > -1
  );
  return sectorsToShow.map((s) => s.description).join(', ');
};

export const showRoundsParticipated = (key, rounds_participated, t) => {
  if (!key) return '';
  const options = [...Object.keys(rounds_participated)];
  const typeInterestOption = options.filter((option) => option === key);
  return t(
    `backoffice.businessRound.addParticipants.filters.rounds_participated.options.${typeInterestOption[0]}`
  );
};
export const isRoundInterested = (events_interested) => {
  return events_interested === ROUNDS_INTERESTED_STATES.YES ? true : false;
};

export const showExportedArticles = (
  exportedArticlesKey,
  exported_categorys
) => {
  if (!exportedArticlesKey) return '';
  const exportedArticlesOption = exported_categorys.filter(
    (option) => option.value === exportedArticlesKey
  );
  return exportedArticlesOption[0].label;
};

export const showRoundsTypeInterested = (
  roundsTypeInterestedKey,
  rounds_interest_options
) => {
  if (!roundsTypeInterestedKey) return '';
  const typeInterestOption = rounds_interest_options.filter(
    (option) => option.value === roundsTypeInterestedKey
  );
  return typeInterestOption[0].label;
};

export const shouldShowOpenTab = (workflow) => {
  return workflow === Backoffice.States.PUBLISHED;
};

export const downloadFile = (dataBlob, customName) => {
  const fileName = makeFilename(customName);
  fileAndLinkDownload(dataBlob, fileName, 'application/vnd.ms-excel');
};

export const makeFilename = (name) => {
  const dateNow = new Date().toLocaleString();
  const appName = process.env.REACT_APP_WEBSITE_NAME;
  const fileName = `${name}_${dateNow}_${appName}.xlsx`;
  return fileName;
};

export const fileAndLinkDownload = (data, fileName, type) => {
  const blobData = new File([data], fileName, { type: type });
  const xmlUrl = window.URL.createObjectURL(blobData);
  const tempLink = document.createElement('a');
  tempLink.href = xmlUrl;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const scrollToLastPosition = (name) => {
  const scrollPosition = sessionStorage.getItem(name);
  if (scrollPosition) {
    window.scrollTo(0, parseInt(scrollPosition));
    sessionStorage.removeItem(name);
  }
};

export const IsResultPageUrl = (url) => {
  const resultPage = '/resultados-busqueda/';
  return url.includes(resultPage);
};

export const getImageBySize = (versions, size) => {
  return versions.find((e) => e?.desc === size)?.url;
};

export const getListBOAccessRoles = () => {
  return Object.values(BORoles);
};

export const getListBOAccessRolesPlusSystemRole = () => {
  const rolesBO = getListBOAccessRoles();
  const systemRole = SpecialsRole.SYSTEM;
  const boAccessRolesPlusSystemRole = [...rolesBO, systemRole];
  return boAccessRolesPlusSystemRole;
};

export const shouldSkipHits = () => {
  const rolesForSkipingHits = getListBOAccessRolesPlusSystemRole();
  const userRoles = AuthService.getCurrentUserRoles();
  const hasSomeRoleForSkipingHits = userRoles.some((r) =>
    rolesForSkipingHits.includes(r)
  );
  return hasSomeRoleForSkipingHits;
};

export const getListCommonGuestRoles = () => {
  return Object.values(CommonGuestRoles);
};

export const isEventMeeting = (event) => {
  return event?.type_class === EVENT_TYPE_CLASS.MEETING;
};

export const buildUserData = () => {
  const { id_user, username, first_name, last_name } =
    AuthService.getUserInfo();
  return {
    id_user: id_user,
    username: username,
    first_name: first_name,
    last_name: last_name,
  };
};

export const ShouldShowUnapprovedPortfolioWarning = () => {
  const { userData } = useCurrentUserData();
  const isPortfolioStateDraft =
    userData?.portfolio_draft?.workflow === Backoffice.States.DRAFT;
  const pendingArticle = findByProperty(
    userData?.category_draft,
    'workflow',
    Backoffice.States.PENDING
  );
  const pendingSection = findByProperty(
    userData?.section_draft,
    'workflow',
    Backoffice.States.PENDING
  );
  const hasAnyArticleOrSectionPending = pendingArticle || pendingSection;
  return (
    !userData?.portfolio_published_exist &&
    isPortfolioStateDraft &&
    hasAnyArticleOrSectionPending
  );
};

export const getActivityUrl = async (activity, isLoggedIn) => {
  let url = getActivityPublicUrl(activity);
  if (isLoggedIn) {
    const isParticipant = await checkIfUserIsParticipant(
      activity?.id_event,
      activity?.type
    );
    if (isParticipant) url = '/tu-mica/rondas/agenda/' + activity?.id_event;
  }
  return url;
};

const getActivityPublicUrl = ({ type, id_activity, id_event }) => {
  let url = '';
  switch (type) {
    default:
    case ACTIVITY_TYPE_FILTERS.SHOW:
      url = `/actividades/${id_activity}`;
      break;
    case ACTIVITY_TYPE_FILTERS.MEETING:
      url = `/actividades/encuentros/${id_activity}/${id_event}`;
      break;
    case ACTIVITY_TYPE_FILTERS.DOMAIN:
      url = `/actividades/encuentros-de-programas/${id_activity}/${id_event}`;
      break;
    case EVENT_TYPE_CLASS.ROUND:
    case ACTIVITY_TYPE_FILTERS.PUBLIC_ROUND:
      url = `/actividades/rondas/${id_activity}/${id_event}`;
      break;
  }

  return url;
};

export const getNoveltyUrl = (id, title) => {
  return `/notas/${createFriendlyLink(id, title)}`;
};

export const checkIfUserIsParticipant = async (id_event, type) => {
  let isParticipant = false;
  const { data } = await EventService.checkIfUserIsParticipant(id_event, type);
  isParticipant = data;
  return isParticipant;
};

export const getLinkToShare = async (activity) => {
  let url = '';
  let activityUrl = await getActivityUrl(activity);
  url = addLocationOrigin(activityUrl);
  return url;
};

export const addLocationOrigin = (path) => {
  return window.location.origin + path;
};

export const getPulicLinkUrl = async (row) => {
  try {
    const { data: activityData } = await SearchService.getActivityByEventId({
      type: row?.type_class ?? row?.type,
      id: row?.id_event,
    });
    return await getActivityUrl(activityData);
  } catch (error) {
    console.error(error);
  }
};

export const buildLinkToShare = async (row) => {
  const relativePath = await getPulicLinkUrl(row);
  return addLocationOrigin(relativePath);
};

export const getLinkToShareNovelties = ({ id_event, title }) => {
  return addLocationOrigin(getNoveltyUrl(id_event, title));
};

export const swapArrayElements = (array, indexA, indexB) => {
  let newArray = array;
  let temp = newArray[indexA];
  newArray[indexA] = newArray[indexB];
  newArray[indexB] = temp;
  return newArray;
};
export const reorderDraggableList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const addHttp = (link) => {
  return /((http|https):\/\/).*/.test(link) ? link : 'http://' + link;
};

export const createTextLinks = (text) => {
  return (text?.toString() || '').replace(
    /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
    function (match, space, url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return (
        space +
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noreferrer">' +
        url +
        '</a>'
      );
    }
  );
};

export const transformUsersObjectToArray = (data) => {
  return data?.recipients?.user && Object.values(data?.recipients?.user);
};

export const getRecipientsFromData = (data, isMassiveNotification) => {
  return isMassiveNotification ? data?.recipients : data?.recipients?.user;
};

export const getQueryParam = (queryParam) => {
  return queryParam ? queryParam : '';
};

export const getSector = (arraySect) => {
  return arraySect?.length > 0 ? arraySect : null;
};

export const getArtitleTypeFilter = (arrayArticleType) => {
  return arrayArticleType?.length > 0 ? arrayArticleType : null;
};
export const getActivityTypeFilter = (arrayActivityType) => {
  return arrayActivityType?.length > 0 ? arrayActivityType : null;
};

export const getTypes = (arrayTypeKey) => {
  if (arrayTypeKey) {
    if (arrayTypeKey !== '') {
      return arrayTypeKey.split(',');
    }
  }
  return '';
};
export const getActivityTypes = (arrayTypeKey) => {
  if (arrayTypeKey) {
    if (arrayTypeKey !== '') {
      return arrayTypeKey.split(',');
    }
  }
  return '';
};

export const getArticleTypeKey = (articleTypesKeyParam) => {
  if (articleTypesKeyParam) {
    if (articleTypesKeyParam !== '') {
      return articleTypesKeyParam.split(',');
    }
  }
  return null;
};
export const getCategoryTypeKey = (categoryTypesKeyParam) => {
  if (categoryTypesKeyParam) {
    if (categoryTypesKeyParam !== '') {
      return categoryTypesKeyParam.split(',');
    }
  }
  return null;
};

export const getStatesIdsFilter = (arrayStatesId) => {
  return arrayStatesId?.length > 0 ? arrayStatesId : null;
};

export const getSectorKeys = (sectorKeyParam) => {
  if (sectorKeyParam) {
    if (sectorKeyParam !== '') {
      return sectorKeyParam.split(',');
    }
  }
  return null;
};

export const isQueryParam = (param) => {
  return param === urlParameter.QUERY ? true : false;
};
export const isSortParam = (param) => {
  return param === urlParameter.SORT ? true : false;
};
export const isSectorParam = (param) => {
  return param === urlParameter.SECTOR ? true : false;
};
export const isPriceParam = (param) => {
  return param === urlParameter.PRICE ? true : false;
};
export const isTypesParam = (param) => {
  return param === urlParameter.TYPE ? true : false;
};
export const isLocationParam = (param) => {
  return param === urlParameter.LOCATION ? true : false;
};
export const isArticleTypesParam = (param) => {
  return param === urlParameter.ARTICLE_TYPE;
};
export const isActivityTypesParam = (param) => {
  return param === urlParameter.ACTIVITY_FILTER;
};

export const isStatesIdParam = (param) => {
  return param === urlParameter.STATE;
};

export const getStatesId = (idsParams) => {
  if (idsParams) {
    if (idsParams !== '') {
      return idsParams.split(',');
    }
  }
  return null;
};
export const getLocations = (idsParams) => {
  if (idsParams) {
    if (idsParams !== '') {
      return idsParams.split('-');
    }
  }
  return null;
};

export const getSearchFilters = (
  queryUrl,
  sectorUrl,
  sortParamUrl,
  typeUrl,
  activityUrl,
  articleTypeUrl,
  statesIdUrl,
  priceUrl,
  locationURL
) => {
  let filters = '?';
  filters += `query=${queryUrl}`;
  if (sectorUrl) filters += `&sector=${sectorUrl}`;
  if (sortParamUrl) filters += `&sort=${sortParamUrl}`;
  if (typeUrl) filters += `&type=${typeUrl}`;
  if (activityUrl) filters += `&activity_filter=${activityUrl}`;
  if (articleTypeUrl) filters += `&article_type=${articleTypeUrl}`;
  if (statesIdUrl) filters += `&id_state=${statesIdUrl}`;
  if (priceUrl) filters += `&price=${priceUrl}`;
  if (locationURL) filters += `&location=${locationURL}`;
  return filters;
};

export const getLineGraphKeys = () => {
  return Object.keys(SESSION_USER_TYPES);
};

export const isTouchDevice = () =>
  window.matchMedia('(any-hover: none)').matches;

export const buildInitialFilters = (selectedTab, windowOptions) => {
  const initialFilters = {
    window: windowOptions[selectedTab],
    date_start_filter: moment().add(-7, 'd'),
    date_end_filter: new Date(),
    device_filter: '',
    sector: '',
    date_group: TYPE_GROUPER_DATE.DAY,
    country_filter: '',
    state_filter: '',
    cycle: [],
  };
  return initialFilters;
};
export const buildNodeFilters = () => {
  const initialFilters = {
    id_domain: '',
    date_start_filter: moment().add(-7, 'd'),
    date_end_filter: new Date(),
    country_filter: '',
    device_filter: '',
    landing_filter: '',
    node_filter: '',
    pageNumber: 0,
    pageSize: BORowsPerPageOptionsStats[0],
  };
  return initialFilters;
};
export const buildIndividualProgramFilters = () => {
  const initialFilters = {
    id_domain: '',
    date_start_filter: '',
    date_end_filter: '',
    country_filter: '',
    device_filter: '',
    node_filter: '',
    pageNumber: 0,
    pageSize: BORowsPerPageOptionsStats[0],
  };
  return initialFilters;
};
export const formatDateForGraph = (fecha) => {
  const parts = fecha.split('.');
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];
  return `${year}-${month}-${day}`;
};

export const handlerPrint = () => {
  window.print();
};

export const getSliderColor = (color) => {
  return SLIDER_COLORS[color] || SLIDER_COLORS_DEFAULT_EXA;
};

export const getSliderColorText = (color) => {
  return SLIDER_COLORS_TEXT[color] || SLIDER_COLORS_TEXT_DEFAULT;
};

export const statesToProgramStates = (states) => {
  const programStates = [];
  for (let state of states) {
    programStates.push({
      id: state?.state_cod,
      description: state?.description,
    });
  }
  return programStates;
};
export const toUpperFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const formatSector = (str) => {
  if (str === 'disenio') {
    str = 'diseño';
  }
  return toUpperFirstLetter(str).replace(/_/g, ' ');
};
export const deparmentsToProgramDepartments = (departments) => {
  const programDepartments = [];
  for (let department of departments) {
    programDepartments.push({
      id: department?.department_cod,
      description: department?.description,
    });
  }
  return programDepartments;
};

export const isOthers = (typeSelected) => {
  if (!typeSelected) {
    return false;
  }
  const arrayCategoryType = Object.values(OthersCategoryType);

  return arrayCategoryType?.some((element) => {
    return typeSelected.includes(element) || typeSelected.includes('others');
  });
};

export const isOthersFilterCount = (typeSelected) => {
  return (
    typeSelected.length > 0 &&
    typeSelected?.filter((e) => e !== SearchEntitiesType.OTHERS).length
  );
};

export const iOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const checkBrowser = () => {
  let browser = '';
  let c = navigator.userAgent.search('Chrome');
  let f = navigator.userAgent.search('Firefox');
  let m8 = navigator.userAgent.search('MSIE 8.0');
  let m9 = navigator.userAgent.search('MSIE 9.0');
  if (c > -1) {
    browser = 'Chrome';
  } else if (f > -1) {
    browser = 'Firefox';
  } else if (m9 > -1) {
    browser = 'MSIE 9.0';
  } else if (m8 > -1) {
    browser = 'MSIE 8.0';
  }
  return browser;
};

export const downloadApp = async (setIsReadyForInstall, setIsModalOpen) => {
  if (iOS()) {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      // do nothing because pwa is installed
    } else {
      setIsModalOpen(true);
    }
  } else if (checkBrowser() === 'Firefox') {
    // do nothing
  } else {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }
};

export const checkAccess = (current_assigned) => {
  if (
    ability.can(
      Backoffice_Rules.Actions.PROGRAMS_ACCESS_TO_ALL,
      Backoffice_Rules.Subjects.BACKOFFICE
    )
  )
    return true;
  return current_assigned;
};

export const getClusterColor = (count) => {
  return count < 10
    ? {
        inside: 'rgba(181, 226, 140, 1);',
        outside: 'rgba(181, 226, 140, 0.6);',
      }
    : count < 50
    ? {
        inside: 'rgba(241, 211, 87, 1)',
        outside: 'rgba(241, 211, 87, 0.6)',
      }
    : {
        inside: 'rgba(253, 156, 115, 1)',
        outside: 'rgba(253, 156, 115, 1)',
      };
};

export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    if (immediate && !timeout) func.apply(context, args);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
  };
};
