import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';
import { ActivityBody } from './ActivityBody';
import { ActivitiesStyles } from './ActivitiesStyles';
import {
  addHttp,
  addLocationOrigin,
  getActivityUrl,
  getNoveltyUrl,
} from '../../utils/utils';
import { isObjectEmpty } from '../../utils/formHelper';
import { NOVELTY_TYPE } from '../../utils/Constants';
import { useCurrentUserData } from '../../hooks/useCurrentUserData';
import ActivityFooter from './ActivityFooter';
import ExternalLink from '../Notes/ExternalLink';
import LinkDialog from '../Dialogs/LinkDialog';

export const ActivityCard = (props) => {
  const {
    activity,
    isHome = false,
    fromBackoffice = false,
    fromHome = false,
    hasShadow = true,
    inActivities = false,
    inAgendaStrip = false,
    inAgendaStripDomain = false,
    inGeneralSearch = false,
    getDescriptionSectors,
  } = props;
  const classes = ActivitiesStyles({
    hasShadow: hasShadow,
    inActivities: inActivities,
    inAgendaStrip,
  });
  const theme = useTheme();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const isLargeWindow = useMediaQuery(theme.breakpoints.up('md'));
  const { isUserLoggedIn } = useCurrentUserData();
  const isLoggedIn = isUserLoggedIn();
  const isNovelty =
    activity?.type === NOVELTY_TYPE.NOVELTY ||
    activity?.data_type === NOVELTY_TYPE.NOVELTY;
  const isContentNote =
    activity?.content_type === NOVELTY_TYPE.NOTE_LINK ||
    activity?.content_type === NOVELTY_TYPE.NOTE;
  const [nonNoveltyUrl, setNonNoveltyUrl] = useState(null);
  const noveltyUrl =
    activity?.link && activity?.link !== ''
      ? addHttp(activity?.link)
      : isContentNote
      ? getNoveltyUrl(activity?.id_item || activity?.id_event, activity?.title)
      : '#null';

  useEffect(() => {
    if (!fromBackoffice && (!isNovelty || fromHome)) {
      getUrl();
    }
    return () => {
      setNonNoveltyUrl(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const getUrl = async () => {
    let url = '';
    url = await getActivityUrl(
      {
        type: activity?.event_type || activity?.type_service || activity?.type,
        id_activity: activity?.id_activity,
        id_event: activity?.id_item || activity?.id_event,
      },
      isLoggedIn
    );
    setNonNoveltyUrl(url);
  };

  const getPathname = () =>
    isNovelty && !fromHome ? noveltyUrl : nonNoveltyUrl;

  const toggleShareDialog = async (e) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  return (
    !isObjectEmpty(activity) &&
    (inAgendaStrip ? (
      <div
        style={{
          height: 'min-content',
          boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.15)',
        }}
      >
        <ExternalLink
          to={getPathname()}
          href={activity?.link || false}
          newWindow={activity?.link_new_window || false}
          classes={classes.link}
          linkOn={!fromBackoffice}
        >
          <ActivityBody
            activity={activity}
            isHome={isHome}
            type={activity?.type}
            hasShadow={hasShadow}
            isNovelty={isNovelty}
            inAgendaStrip={inAgendaStrip}
            inAgendaStripDomain={inAgendaStripDomain}
            inGeneralSearch={inGeneralSearch}
            getDescriptionSectors={getDescriptionSectors}
          />
          <ActivityFooter {...props} toggleShareDialog={toggleShareDialog} />
        </ExternalLink>
        <LinkDialog
          open={isOpen}
          onClose={toggleShareDialog}
          link={addLocationOrigin(getPathname())}
          title={t(`dialogs.shareDialog.title`)}
        />
      </div>
    ) : (
      <Card className={classes.cardContainer}>
        <div
          className={classnames(
            classes.itemsMainContainer,
            isLargeWindow ? classes.itemsMainContainerLg : ''
          )}
        >
          <ExternalLink
            to={getPathname()}
            href={activity?.link || false}
            newWindow={activity?.link_new_window || false}
            classes={classes.link}
            linkOn={!fromBackoffice}
          >
            <ActivityBody
              activity={activity}
              isHome={isHome}
              type={activity?.type}
              hasShadow={hasShadow}
              isNovelty={isNovelty}
              inAgendaStrip={inAgendaStrip}
              inAgendaStripDomain={inAgendaStripDomain}
              inGeneralSearch={inGeneralSearch}
              getDescriptionSectors={getDescriptionSectors}
            />
            <ActivityFooter {...props} toggleShareDialog={toggleShareDialog} />
          </ExternalLink>
          <LinkDialog
            open={isOpen}
            onClose={toggleShareDialog}
            link={addLocationOrigin(getPathname())}
            title={t(`dialogs.shareDialog.title`)}
          />
        </div>
      </Card>
    ))
  );
};
