import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomCarouselWithText from '../../../../components/CustomCarousel/CustomCarouselWithText';
import HomeSectors from './HomeSectors';
import HomePortfolios from './HomePortfolios';
import HomeProducts from './HomeProducts';
import Highlight from './highlight';
import HomeActivities from './HomeActivities';
import MultiFeatured from './MultiFeatured/MultiFeatured';
import HomeNotes from './HomeNotes';
import SearchService from '../../../../services/SearchService';
import SEO from '../../../../shared/SEO';
import { useCurrentUserData } from '../../../../hooks/useCurrentUserData';
import { useHomeStyles } from './useHomeStyles';
import { useKey } from '../../../../hooks/useKey';
import { PortfolioSectorStyles } from '../../../../components/Sectors/PortfoliosBySectors/PortfoliosBySectorStyles';
import { HomeCultureMap } from './HomeCultureMap';
import { useSectors } from '../../../../hooks/useSectors';

const NotPublishedBanner = React.lazy(() => import('./NotPublishedBanner'));
const UserNotRegisterBanner = React.lazy(() =>
  import('../../../../components/Sectors/UserNotRegisterBanner')
);

function Home() {
  const { t } = useTranslation();
  const classes = useHomeStyles();
  const classesPortfolio = PortfolioSectorStyles();
  const { sectors, getSectorDescriptionByKey } = useSectors();
  const [homeData, setHomeData] = useState(null);
  const [tabPressed, setTabPressed] = useState(false);
  const { userData, isUserLoggedIn } = useCurrentUserData();
  const isLoggedIn = isUserLoggedIn();
  const portfolioPublished = userData?.portfolio_published_exist;

  useKey('Tab', handleTab);
  function handleTab() {
    setTabPressed(true);
  }

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = async () => {
    try {
      await getHomeData();
    } catch (error) {
      console.error(error);
    }
  };

  const getHomeData = async () => {
    return await SearchService.getHome()
      .then(({ data }) => {
        setHomeData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <SEO title={t('description')} ogTitle={t('home.title')} />
      <div className={classes.boxSlider}>
        <CustomCarouselWithText
          items={homeData?.slider}
          indicators={tabPressed ? false : true}
          autoPlay={tabPressed ? false : true}
        />
      </div>
      <div className={classes.boxMainHome}>
        <HomeSectors sectors={sectors} />
        <HomePortfolios
          portfolios={homeData?.portfolios}
          getSectorDescriptionByKey={getSectorDescriptionByKey}
          title={t('home.carrousels.portfolios')}
          classes={classesPortfolio}
          skipTo='products'
        />
        <HomeProducts articles={homeData?.articles} />
        <HomeCultureMap isHighlightEnabled={homeData?.highlight} />
        <Highlight highlight={homeData?.highlight} />
        <div className={classes.multiHighlightContainer}>
          <HomeActivities
            activities={homeData?.activities?.items}
            getSectorDescriptionByKey={getSectorDescriptionByKey}
          />
          <div className={classes.multiHighlightAndNotesWrapper}>
            <div className={classes.multiFeaturedWrapper}>
              <MultiFeatured
                items={homeData?.featured}
                tabPressed={tabPressed}
                indicators={tabPressed ? false : true}
                autoPlay={tabPressed ? false : true}
              />
            </div>
            <div className={classes.notesWrapper}>
              <HomeNotes notes={homeData?.notes} />
            </div>
          </div>
        </div>
        {!isLoggedIn ? (
          <UserNotRegisterBanner />
        ) : !portfolioPublished ? (
          <NotPublishedBanner />
        ) : null}
      </div>
    </>
  );
}

export default Home;
