import moment from 'moment/min/moment-with-locales';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../services/AuthService';
import {
  Backoffice,
  CABA,
  DATE_FORMAT,
  EVENT_TYPE_MEETING,
  Forms,
  HostRoles,
  MAILING_OPTIONS,
  RoundsLocation,
  RoundsParticipantStatus,
  SLIDER_ACTIONS_TYPE,
  RoundsNotifyTypes,
  RoundsRoles,
  PARTICIPANT_PROCESSING_STATE,
  EntitiesType,
  paramsRegisterPortfolio,
  paletteColorStats,
  COLLECTION_STATUS,
  LINKED_CC_PRODUCTS_STATES,
  LINKED_PRODUCTS_STATES,
} from './Constants';

export const isDateValid = (date) => {
  return moment(date).isValid();
};
export const formatRequestDate = (date) => {
  return isDateValid(date) ? moment(date).format() : '';
};

export const formatRequestDateStartOfDay = (date) => {
  return isDateValid(date) ? moment(date).startOf('day').format() : '';
};

export const formatRequestDateEndOfDay = (date) => {
  return isDateValid(date) ? moment(date).endOf('day').format() : '';
};

export const formatRequestDateUTCEndOfDay = (date) => {
  return isDateValid(date) ? moment(date).endOf('day').format() : '';
};

export const isConfirmed = (status) => {
  return status === RoundsParticipantStatus.CONFIRMED ? true : false;
};

export const isSeller = (role) => {
  return role === RoundsRoles.SELLER;
};

export const formatRangeDate = (dateStart, dateEnd, lng, t) => {
  const textStart = formatReadableDate(dateStart, lng);
  let text = textStart;
  if (dateEnd) {
    const textEnd = formatReadableDate(dateEnd, lng);
    if (textEnd !== textStart) {
      text = t('rounds.dates', {
        dateStart: textStart,
        dateEnd: textEnd,
      });
    }
  } else {
    const textStart = formatReadableDate(dateStart, lng);
    text = textStart;
  }
  return text;
};

export const formatRangeTime = (dateStart, dateEnd, lng, t) => {
  const timeStart = formatDateToTime(dateStart, lng);
  let text = t('rounds.time', {
    date: timeStart,
  });
  if (dateEnd) {
    const timeEnd = formatDateToTime(dateEnd, lng);
    if (dateStart !== dateEnd) {
      text = t('rounds.times', {
        from: timeStart,
        to: timeEnd,
      });
    }
  }
  return text;
};

export const getEventDateText = (isPublicRound, dateStart, dateEnd, lng, t) => {
  let dateText = '';
  if (isPublicRound) {
    dateText = formatRangeDate(dateStart, dateEnd, lng, t);
  } else {
    dateText = formatDate(dateStart, DATE_FORMAT.READABLE_DAY, lng);
  }
  return dateText;
};

export const formatDate = (date, format, language) => {
  moment.locale(language);
  const localDate = convertDateToLocalTime(date);
  return isDateValid(localDate) ? moment(localDate).format(format) : '';
};

export const getMonths = (language) => {
  moment.locale(language);
  return moment.months();
};

export const getSecondsFromDate = (date) => {
  var a = moment.utc(date, 'HH:mm:ss'),
    b = a.clone().startOf('day');
  return a.diff(b, 'seconds');
};

export const formatContentDuration = (seconds) => {
  if (seconds === -1) return '';
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  if (hours) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  if (minutes) {
    return `${minutes}m ${seconds}s`;
  }
  if (seconds) {
    return `${seconds}s`;
  }
  return '';
};

export const capitalizeText = (text) => {
  return text && text[0].toUpperCase() + text.substr(1);
};

export const formatReadableDate = (date, language) => {
  return formatDate(date, DATE_FORMAT.STANDARD, language);
};

export const formatReadableDateShortYear = (date, language) => {
  return formatDate(date, DATE_FORMAT.STANDARD_SHORT_YEAR, language);
};

export const formatReadableDateTime = (date, language) => {
  return formatDate(date, DATE_FORMAT.STANDARD_TIME, language);
};

export const formatReadableDateTimeWithoutYear = (date, language) => {
  return formatDate(date, DATE_FORMAT.STANDARD_TIME_WITHOUT_YEAR, language);
};

export const formatReadableDateTimeWithDay = (date, language) => {
  return formatDate(date, DATE_FORMAT.STANDARD_TIME_WITH_DAY, language);
};

export const formatReadableDayAndHour = (date, language) => {
  return formatDate(date, DATE_FORMAT.READABLE_DAY_AND_HOUR, language);
};

export const formatDateToReadableDay = (date, language) => {
  return formatDate(date, DATE_FORMAT.READABLE_DAY, language);
};

export const formatDateToReadableDayAndMonth = (date, language) => {
  return formatDate(
    date,
    DATE_FORMAT.READABLE_DAY_WITH_READABLE_MONTH,
    language
  );
};

export const formatDateToYear = (date, language) => {
  return formatDate(date, DATE_FORMAT.YEAR, language);
};

export const formatDateToReadableMonth = (date, language) => {
  return formatDate(date, DATE_FORMAT.READABLE_MONTH, language);
};

export const formatDateToTime = (date, language) => {
  return formatDate(date, DATE_FORMAT.TIME, language);
};
export const setTimeToDate = (date, time) => {
  let baseDate = moment(date);
  let baseTime = moment(time);
  baseDate.set({
    hour: baseTime.get('hour'),
    minute: baseTime.get('minute'),
    second: baseTime.get('second'),
  });
  return baseDate.toISOString();
};

export const isDatePassed = (date) => {
  if (date === null) return false;
  return moment().diff(date) > 0;
};

export const buildGeoData = (values, countries, states, departments) => {
  const countryOption = countries.find((c) => c.country_iso === values.country);
  const stateOption = states.find((s) => s.state_cod === values.state);
  const departmentOption = departments.find(
    (d) => d.department_cod === values.department
  );
  return {
    country: {
      id: countryOption.country_iso,
      description: countryOption.description,
    },
    state: {
      id: stateOption ? stateOption.state_cod : Forms.Geo.OTHER,
      description: stateOption ? stateOption.description : values.state,
    },
    department: {
      id: departmentOption ? departmentOption.department_cod : Forms.Geo.OTHER,
      description: departmentOption
        ? departmentOption.description
        : values.department,
    },
  };
};

export const buildLocation = (data) => {
  let location = '';
  if (data.department && data.state) {
    const department = data.department?.description;
    const state = data.state?.description;
    const isCaba = department === CABA.LONG && state === CABA.LONG;
    location = isCaba ? department : department + ', ' + state;
  }
  if (data.country && data.state) {
    location = data.state?.description + ', ' + data.country?.description;
  }
  return location;
};

export const buildProfileLocation = (data) => {
  let location = '';
  if (data.department && data.state) {
    const department = data.department?.description;
    const state = data.state?.description;
    const isCaba = department === CABA.LONG && state === CABA.LONG;
    location = isCaba ? department : department + ', ' + state;
  } else if (data.country && data.state) {
    location = data.state?.description + ', ' + data.country?.description;
  }
  return location;
};

export const shortDescriptionCABA = (description) => {
  return description === CABA.LONG ? CABA.SHORT : description;
};

export const BObuildLocation = (data) => {
  let location = '';
  if (data.country && data.state) {
    location =
      shortDescriptionCABA(data.state?.description) + ', ' + data.country?.id;
  }
  return location;
};

export const buildGeoEditData = (
  data,
  getStatesByCountry,
  getDepartmentsByState
) => {
  if (data.country && data.state && data.department) {
    let geoData = {
      country: data.country.id,
      state: data.state.id,
      department: data.department.id,
    };
    if (data.state.id !== Forms.Geo.OTHER) {
      getStatesByCountry(data.country.id);
    } else {
      geoData.state = data.state.description;
    }
    if (data.department.id !== Forms.Geo.OTHER) {
      getDepartmentsByState(data.state.id, data.country.id);
    } else {
      geoData.department = data.department.description;
    }

    return {
      ...data,
      ...geoData,
    };
  } else {
    return { ...removeEmptyAttributes(data) };
  }
};

export const onCheckboxChange = (e, setFieldValue, formValues, formKey) => {
  const value = e.target.value;
  const options = formValues[formKey];
  if (options) {
    var index = options.findIndex((x) => x === value);
    if (index === -1) {
      options.push(value);
    } else {
      options.splice(options.indexOf(value), 1);
    }
  }
  setFieldValue(formKey, options);
};
export const onCheckboxChangeObjectArticle = (
  e,
  setFieldValue,
  formValues,
  formKey,
  allOptions
) => {
  const id = e.target.value;
  let options = formValues;
  let found = options.find((article) => article.id_article === id);
  if (found === undefined) {
    let elemToAdd = allOptions.find((article) => article.id_article === id);
    options.push(elemToAdd);
  } else {
    options = options.filter((article) => article.id_article !== id);
  }
  setFieldValue(formKey, options);
};

export const onCheckboxChangeObject = (
  e,
  setFieldValue,
  formValues,
  formKey,
  allOptions
) => {
  const id = e.target.value;
  let options = formValues;
  let found = options.find((section) => section.id_section === id);
  if (found === undefined) {
    let elemToAdd = allOptions.find((section) => section.id_section === id);
    options.push(elemToAdd);
  } else {
    options = options.filter((section) => section.id_section !== id);
  }
  setFieldValue(formKey, options);
};

export const isObjectEmpty = (object) => {
  return object ? Object.keys(object).length === 0 : true;
};

export const getLastHistoryEntry = (history) => {
  if (history) {
    const lastHistoryEntryPos = history.length === 0 ? 0 : history.length - 1;
    return history[lastHistoryEntryPos];
  } else {
    return null;
  }
};

export const getLastHistoryComments = (history) => {
  const lastHistoryEntry = getLastHistoryEntry(history);
  return lastHistoryEntry ? lastHistoryEntry.changes.description : '';
};

export const getEquivalentStatusAction = (state) => {
  switch (state) {
    case Backoffice.States.PUBLISHED:
      return Backoffice.Actions.SHOW;
    case Backoffice.States.HIDDEN:
      return Backoffice.Actions.HIDE;
    case Backoffice.States.DELETED_PUBLISHED:
      return Backoffice.Actions.DELETE_PUBLISHED;
    case Backoffice.States.DELETED:
      return Backoffice.Actions.DELETE;
    default:
      return Backoffice.Actions.SHOW;
  }
};

export const findByProperty = (array, prop, value) => {
  let entry = null;
  if (array && array?.length !== 0) {
    entry = array.find((element) => element[prop] === value);
  }
  return entry;
};

export const filterByProperty = (array, prop, value) => {
  let entries = null;
  if (array && array?.length !== 0) {
    entries = array.filter((element) => element[prop] === value);
  }
  return entries;
};

export const replaceNull = (someObj, replaceValue = '***') => {
  const replacer = (key, value) =>
    String(value) === 'null' || String(value) === 'undefined'
      ? replaceValue
      : value;
  //^ because you seem to want to replace (strings) "null" or "undefined" too
  return JSON.parse(JSON.stringify(someObj, replacer));
};

export const buildInitials = (first_name, last_name) => {
  let first = '';
  let last = '';
  if (first_name) {
    first = first_name.substring(0, 1).toUpperCase();
  }
  if (last_name) {
    last = last_name.substring(0, 1).toUpperCase();
  }
  return first + last;
};

export const buildFiltersData = (filters, value) => {
  const filterIndex = filters.indexOf(value);
  if (filterIndex === -1) {
    return [...filters, value];
  } else {
    return [...filters.filter((filter) => filter !== value)];
  }
};
export const getArticlesByType = (articles, type) => {
  let articlesFound = null;
  if (articles) {
    articlesFound = articles.filter(
      (element) => element['article_type'] === type
    );
  }
  return articlesFound ? articlesFound : [];
};

export const validateDate = (value) => {
  if (value) {
    const date = moment(value);
    return (
      date.isValid() && date.isAfter('1900-01-01') && date.isBefore(new Date())
    );
  }
  return false;
};

export const removeEmptyAttributes = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
};

export const removeEmptyStringAttributes = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] === '' && delete obj[key]);
};

export const updateArray = (
  arrayToUpdate,
  idProp,
  ids,
  propToUpdate,
  propValueToUpdate
) => {
  let updatedArray = arrayToUpdate;
  updatedArray = updatedArray.map((elem) => {
    if (ids.indexOf(elem[idProp]) > -1) {
      elem[propToUpdate] = propValueToUpdate;
    }
    return elem;
  });
  return updatedArray;
};

export const convertDateToLocalTime = (dateToConvert) => {
  if (dateToConvert) {
    const date = moment(dateToConvert);
    if (date.isValid()) {
      const dateUTC = moment.utc(dateToConvert).format();
      const dateLocal = moment.utc(dateUTC).toDate();
      return dateLocal;
    }
  } else {
    return null;
  }
};

export const convertDateToLocalTimeWithFormat = (dateToConvert) => {
  if (dateToConvert) {
    const date = moment(dateToConvert);
    if (date.isValid()) {
      const dateUTC = moment.utc(dateToConvert).format('DD MMMM YYYY');
      return dateUTC;
    }
  }
};

export const getLocalHourFromDate = (date) => {
  if (date) {
    const localDate = convertDateToLocalTime(date);
    const localTime =
      localDate?.getMinutes() === 0
        ? localDate?.getHours()
        : localDate?.getHours() + ':' + localDate?.getMinutes();
    return localTime;
  }
};

export const getLocalTimeFromDate = (date) => {
  if (date) {
    const localDate = convertDateToLocalTime(date);
    const localHour = ('0' + localDate?.getHours()).slice(-2);
    const localMinutes = ('0' + localDate?.getMinutes()).slice(-2);
    return localHour + ':' + localMinutes;
  }
};

export const getLocalTimeFromDateCustom = (date) => {
  if (date) {
    const localDate = convertDateToLocalTimeWithFormat(date);
    return localDate;
  }
};

export const getParticipantsByRole = (list, role) => {
  let members = [];
  if (list.length !== 0) {
    members = list.filter((member) => member.role === role);
  }
  return members;
};

export const getParticipantPossibleStatus = (status, hasSchedule = false) => {
  let possibleStatuses = [];
  switch (status) {
    case RoundsParticipantStatus.NOT_INVITED:
    case RoundsParticipantStatus.PENDING:
    case RoundsParticipantStatus.NOT_INTERESTED:
      if (hasSchedule) {
        possibleStatuses = [RoundsParticipantStatus.INTERESTED];
      }
      break;
    case RoundsParticipantStatus.CONFIRMED:
      if (hasSchedule) {
        possibleStatuses = [];
      } else {
        possibleStatuses = [RoundsParticipantStatus.INTERESTED];
      }
      break;
    case RoundsParticipantStatus.REJECTED:
      possibleStatuses = [RoundsParticipantStatus.INTERESTED];
      break;
    case RoundsParticipantStatus.INTERESTED:
      if (hasSchedule) {
        possibleStatuses = [
          RoundsParticipantStatus.WAITING_LIST,
          RoundsParticipantStatus.REJECTED,
        ];
      } else {
        possibleStatuses = [
          RoundsParticipantStatus.WAITING_LIST,
          RoundsParticipantStatus.PRE_SELECTED,
          RoundsParticipantStatus.REJECTED,
        ];
      }
      break;

    case RoundsParticipantStatus.PRE_SELECTED:
      if (hasSchedule) {
        possibleStatuses = [];
      } else {
        possibleStatuses = [
          RoundsParticipantStatus.WAITING_LIST,
          RoundsParticipantStatus.CONFIRMED,
          RoundsParticipantStatus.REJECTED,
        ];
      }
      break;

    case RoundsParticipantStatus.WAITING_LIST:
      if (hasSchedule) {
        possibleStatuses = [
          RoundsParticipantStatus.INTERESTED,
          RoundsParticipantStatus.REJECTED,
        ];
      } else {
        possibleStatuses = [
          RoundsParticipantStatus.CONFIRMED,
          RoundsParticipantStatus.PRE_SELECTED,
          RoundsParticipantStatus.REJECTED,
        ];
      }
      break;
    default:
      break;
  }
  return possibleStatuses;
};

export const getParticipantUndoStatus = (currentStatus, option) => {
  switch (currentStatus) {
    case RoundsParticipantStatus.REJECTED:
      return option === RoundsParticipantStatus.INTERESTED;
    case RoundsParticipantStatus.CONFIRMED:
      return (
        option === RoundsParticipantStatus.PRE_SELECTED ||
        option === RoundsParticipantStatus.WAITING_LIST
      );
    case RoundsParticipantStatus.WAITING_LIST:
      return (
        option === RoundsParticipantStatus.PRE_SELECTED ||
        option === RoundsParticipantStatus.REJECTED
      );
    case RoundsParticipantStatus.PRE_SELECTED:
      return option === RoundsParticipantStatus.REJECTED;
    default:
      return false;
  }
};

export const isRoundStateForAdminAction = (status) => {
  return (
    status === RoundsParticipantStatus.INTERESTED ||
    status === RoundsParticipantStatus.NOT_INVITED ||
    status === RoundsParticipantStatus.PRE_SELECTED
  );
};

export const getUnseenChats = (messages, id_user) => {
  return messages
    .filter((message) => message.from_user.id_user !== id_user && !message.seen)
    .map((message) => message.id_chat);
};

export const calculateTimeLeft = (dateFinish, dateStart = new Date()) => {
  let timeLeft = {};
  if (dateFinish === null) return timeLeft;

  dateFinish = new Date(convertDateToLocalTime(dateFinish));
  const difference = +dateFinish - +dateStart;

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const isSelfPortfolio = (id_portfolio) => {
  return AuthService.getUserId() === id_portfolio;
};

export const getChatNickname = (user) => {
  return user?.portfolio_published?.project_name
    ? user?.portfolio_published?.project_name
    : user?.first_name && user?.last_name
    ? `${user?.first_name} ${user?.last_name}`
    : user.username
    ? user.username.split('@')[0]
    : '';
};

export const getChatNicknameMessages = (conversation) => {
  if (conversation?.node) {
    return conversation?.node?.name;
  } else {
    return conversation?.to_user?.portfolio_published?.project_name
      ? conversation?.to_user?.portfolio_published?.project_name
      : conversation?.to_user?.first_name && conversation?.to_user?.last_name
      ? `${conversation?.to_user?.first_name} ${conversation?.to_user?.last_name}`
      : conversation.to_user?.username
      ? conversation.to_user?.username.split('@')[0]
      : '';
  }
};

export const getEventTypeMeetingOptions = (t, TRANSLATION_KEY) => {
  const options = Object.values(EVENT_TYPE_MEETING)
    .filter((option) => option !== EVENT_TYPE_MEETING.BUSINESS_ROUND)
    .map((option) => {
      return {
        description: t(TRANSLATION_KEY + 'options.' + option),
        value: option,
      };
    });

  return options;
};

export const getLinkedCCProductsOptions = (t, TRANSLATION_KEY) => {
  const options = Object.values(LINKED_CC_PRODUCTS_STATES).map((option) => {
    return {
      description: t(TRANSLATION_KEY + 'options.' + option),
      value: option,
    };
  });
  return options;
};

export const getLinkedProductsOptions = (t, TRANSLATION_KEY) => {
  const options = Object.values(LINKED_PRODUCTS_STATES).map((option) => {
    return {
      description: t(TRANSLATION_KEY + 'options.' + option),
      value: option,
    };
  });
  return options;
};

export const generateObjectFromArrayPie = (name, value, color) => {
  return { name: name, value: value, color: color };
};

export const getUserFullName = (user) => {
  return user && user?.first_name && user?.first_name + ' ' + user?.last_name;
};

export const isPublishButtonEnabled = (sliders, config) => {
  let quantitySlidersForPublish = sliders.filter(
    (slide) => slide.publish === true
  ).length;
  if (quantitySlidersForPublish < config.MIN) {
    return false;
  }
  if (quantitySlidersForPublish > config.MAX) {
    return false;
  }
  return true;
};

export const slideToSend = (slide, index, nameId) => {
  return {
    [nameId]: slide?.[nameId],
    publish: slide.publish,
    order: String(index + 1),
  };
};

export const buildSliderRequest = (sliders, nameId) => {
  let requestValues = sliders.map((slide, index) =>
    slideToSend(slide, index, nameId)
  );
  return requestValues;
};

export const wasSliderEdited = (statusSlider) => {
  return statusSlider?.action === SLIDER_ACTIONS_TYPE.EDIT;
};

export const hasRecipients = (detailData) => {
  return detailData?.recipients && detailData?.recipients?.total_user !== 0;
};

export const searchUserPreference = (userPreferences, type) => {
  const notificationPreference = userPreferences.find(
    (preference) => preference.type === type
  );
  return notificationPreference.active;
};

export const isNotificationPreferenceActived = (userPreferences) => {
  return searchUserPreference(userPreferences, MAILING_OPTIONS.NOTIFICATIONS); // notifications = mails - mensajeria
};

export const roundLocation = (
  t,
  location,
  location_address,
  location_desc,
  participant_status_schedule
) => {
  let locationText = '';
  if (location === RoundsLocation.OTHER) {
    if (
      participant_status_schedule === RoundsParticipantStatus.CONFIRMED &&
      location_desc &&
      location_address
    ) {
      locationText = t('backoffice.businessRound.form.location.description', {
        desc: location_desc,
        address: location_address,
      });
    } else {
      locationText = t(
        'backoffice.businessRound.form.location.descriptionWithoutAddress',
        {
          desc: location_desc,
        }
      );
    }
  } else {
    locationText = t('rounds.location.' + location);
  }
  return locationText;
};

export const roundLocationEvent = (
  t,
  { location, location_address, location_desc, participant_status_schedule }
) => {
  return roundLocation(
    t,
    location,
    location_address,
    location_desc,
    participant_status_schedule
  );
};

export const roundDateStart = (event) => {
  const dateStart =
    event?.schedule_start &&
    event?.participant_status === RoundsParticipantStatus.CONFIRMED &&
    !isEventRoleHost(event?.role)
      ? event?.schedule_start
      : event?.date_start;
  return dateStart;
};

export const roundDateEnd = (event) => {
  const dateEnd =
    event?.schedule_end &&
    event?.participant_status === RoundsParticipantStatus.CONFIRMED &&
    !isEventRoleHost(event?.role)
      ? event?.schedule_end
      : event?.date_end;
  return dateEnd;
};

export const hasAnyEvent = (data) => {
  return (
    data?.confirmed?.length > 0 ||
    data?.host?.length > 0 ||
    data?.invitation?.length > 0
  );
};

export const isEventRoleHost = (role) => {
  return HostRoles.includes(role);
};

export const isSellerPreselected = (element) => {
  return element?.status === RoundsNotifyTypes.PRESELECTED &&
    element?.role === RoundsRoles.SELLER
    ? true
    : false;
};

export const isSellerConfirmed = (element) => {
  return element?.status === RoundsNotifyTypes.CONFIRMED &&
    element?.role === RoundsRoles.SELLER
    ? true
    : false;
};

export const isStandBy = (currentProcessingState) => {
  return currentProcessingState === PARTICIPANT_PROCESSING_STATE.STAND_BY;
};

export const isSuccess = (currentProcessingState) => {
  return currentProcessingState === PARTICIPANT_PROCESSING_STATE.SUCCESS;
};

export const isError = (currentProcessingState) => {
  return currentProcessingState === PARTICIPANT_PROCESSING_STATE.ERROR;
};

export const isLoading = (currentProcessingState) => {
  return currentProcessingState === PARTICIPANT_PROCESSING_STATE.LOADING;
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const getPortfolioState = (id, published, type) => {
  let statePortfolio;
  if (EntitiesType.SECTION === type) {
    let section = findByProperty(published, 'id_section', id);
    statePortfolio = section?.portfolio?.published?.workflow;
  } else {
    let article = findByProperty(published, 'id_article', id);
    statePortfolio = article?.portfolio?.published?.workflow;
  }
  return statePortfolio;
};

export const getAvatar = (message) => {
  return !isObjectEmpty(message.portfolio_published)
    ? message.portfolio_published?.image?.versions[0].url
    : '';
};

export const getDescriptionStatesById = (states, idState) => {
  let description = states.find((element) => element?.state_cod === idState);
  return description ? description?.description : '';
};
export const getDescriptionCountryISO = (countries, name) => {
  let description = countries.find(
    (element) => element?.country_iso === name.toUpperCase()
  );
  return description ? description?.description : 'Resto del Mundo';
};

export const getArrayInObject = (array) => {
  let obj = {};

  array.forEach((element) => {
    obj = { ...obj, ...element };
  });

  return obj;
};

export const hasStates = (states) => {
  return states?.length > 0;
};

export const isSetRegisterPortfolio = (params) => {
  return params === paramsRegisterPortfolio;
};

export const getRegisterByProvince = (data, states) => {
  let registerByProvince = [];

  data.forEach((element, index) => {
    let description = getDescriptionStatesById(states, element?.provincia_id);
    description &&
      registerByProvince.push(
        generateObjectFromArrayPie(
          description,
          data[index].count,
          paletteColorStats[index]
        )
      );
  });
  return registerByProvince;
};
export const getRegisterByCountry = (data, countries) => {
  let registerByCountry = [];
  data.forEach((element, index) => {
    let description = getDescriptionCountryISO(countries, element?.name);
    description &&
      registerByCountry.push(
        generateObjectFromArrayPie(
          description,
          data[index].count,
          paletteColorStats[index]
        )
      );
  });

  return registerByCountry;
};

function secondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remainingSeconds = seconds - hours * 3600 - minutes * 60;

  const pad = (num) => {
    return num < 10 ? '0' + num : num;
  };

  return pad(hours) + ':' + pad(minutes) + ':' + pad(remainingSeconds);
}

export const formatSecondsToReadableDate = (seconds) => {
  if (!seconds) return '';
  return secondsToHMS(seconds);
};

export const formatPercent = (value) => {
  if (!value) return '';
  return value + ' %';
};

export const formatPercentWithParents = (n1, n2) => {
  if (!n1 || !n2) return '';
  let value = ((n1 / n2) * 100).toFixed(2);
  let number = formatPercent(value);
  let numberWithParents = '(' + number + ')';
  return numberWithParents;
};

export const buildParamsPlots = (filters) => {
  const params = filters;
  if (filters?.date_start_filter) {
    params.date_start_filter = formatRequestDateStartOfDay(
      filters.date_start_filter
    );
  }
  if (filters?.date_end_filter) {
    params.date_end_filter = formatRequestDateEndOfDay(filters.date_end_filter);
  }

  if (filters?.pageSize) {
    params.pageSize = filters.pageSize;
  }
  if (filters?.pageNumber) {
    params.pageNumber = filters.pageNumber;
  }
  return params;
};

export const buildParamsNodes = (filters) => {
  const params = filters;
  if (filters?.date_start_filter) {
    params.date_start_filter = formatRequestDateStartOfDay(
      filters.date_start_filter
    );
  }
  if (filters?.date_end_filter) {
    params.date_end_filter = formatRequestDateEndOfDay(filters.date_end_filter);
  }

  return params;
};

export const buildParamsDomainNodes = (filters) => {
  const params = filters;
  if (filters?.date_start_filter) {
    params.date_start_filter = formatRequestDateStartOfDay(
      filters.date_start_filter
    );
  }
  if (filters?.date_end_filter) {
    params.date_end_filter = formatRequestDateEndOfDay(filters.date_end_filter);
  }
  if (filters?.date_group) {
    params.date_group = filters.date_group;
  }

  return params;
};
export const getStatePublished = (hidden) => {
  const state = hidden ? Backoffice.States.HIDDEN : Backoffice.States.PUBLISHED;
  return state;
};

export const showHistoryAuthor = (entry) => {
  const state = entry?.changes?.value_after;
  return (
    state === Backoffice.States.APPROVED ||
    state === Backoffice.States.REJECTED ||
    state === Backoffice.States.SUSPENDED
  );
};

export const hiddenByAdminLastHistory = (data) => {
  return transformHistory(data, COLLECTION_STATUS.PUBLISHED);
};

export const getHistoryMotive = (data) => {
  return data?.changes?.admin_reason;
};
export const getHistoryMessage = (data) => {
  return data?.changes?.description;
};

const transformHistory = (data, collectionStatus) => {
  const historyData = data[collectionStatus];
  const date = historyData?.modified_date;
  const name = historyData?.change_by?.username;
  const motive = getHistoryMotive(historyData);
  const message = getHistoryMessage(historyData);
  const status = historyData?.changes?.value_after;
  return { status, date, name, motive, message };
};

export const isStateSuspended = (hidden) => {
  return hidden === Backoffice.States.SUSPENDED;
};

export const getStateHidden = (state) => {
  return state === Backoffice.States.HIDDEN ||
    state === Backoffice.States.SUSPENDED
    ? state
    : false;
};

export const isPending = (entity) => {
  return entity?.workflow === Backoffice.States.PENDING;
};

export const isHiddenByAdmin = (row) => {
  return (
    row?.workflow === Backoffice.States.SUSPENDED ||
    row?.workflow_published === Backoffice.States.SUSPENDED
  );
};

export const isSuspended = (publishedState) => {
  return publishedState === Backoffice.States.SUSPENDED;
};

export const isHidden = (state) => {
  return state === Backoffice.States.HIDDEN;
};

export const isPublished = (state) => {
  return state === Backoffice.States.PUBLISHED;
};

export const isHiddenPortfolio = (state) => {
  return state === Backoffice.States.HIDDEN_PORTFOLIO;
};
export const isApproved = (state) => {
  return state === Backoffice.States.APPROVED;
};
export const isDraft = (state) => {
  return state === Backoffice.States.DRAFT;
};

export const isPreApproved = (state) => {
  return state === Backoffice.States.PRE_APPROVED;
};

export const isPendingAdmin = (state) => {
  return state === Backoffice.States.PENDING_ADMIN;
};

export const isRejected = (state) => {
  return state === Backoffice.States.REJECTED;
};

export const isHiddenOrHiddenPortfolio = (state, statePortfolio) => {
  return isHiddenPortfolio(statePortfolio) || isHidden(state);
};

export const isSuspendedOrIsPortfolioSuspended = (row, isDraft) => {
  return (
    isArticleOrSectionSuspended(row, isDraft) ||
    isPortfolioSuspended(row, isDraft)
  );
};

export const isArticleOrSectionSuspended = (row, isDraft) => {
  if (isDraft) {
    return (
      row?.workflow === Backoffice.States.SUSPENDED ||
      row?.workflow_published === Backoffice.States.SUSPENDED
    );
  } else {
    return row?.workflow === Backoffice.States.SUSPENDED;
  }
};

export const isPortfolioSuspended = (row, isDraft) => {
  if (isDraft) {
    return (
      row?.portfolio?.draft === Backoffice.States.SUSPENDED ||
      row?.portfolio?.published?.workflow === Backoffice.States.SUSPENDED
    );
  } else {
    return row?.portfolio?.published?.workflow === Backoffice.States.SUSPENDED;
  }
};

export const getPortfolioIdByEntity = (entity, params) => {
  if (entity === EntitiesType.SECTION)
    return params?.section?.portfolio?.published?.id_portfolio;
  if (entity === EntitiesType.ARTICLE)
    return params?.article?.portfolio?.published?.id_portfolio;

  return params?.portfolio?.id_portfolio;
};

export const isPortfolioSuspendedByEntity = (entity, params) => {
  if (entity === EntitiesType.SECTION)
    return isHiddenByAdmin(params?.section?.portfolio?.published);
  if (entity === EntitiesType.ARTICLE)
    return isHiddenByAdmin(params?.article?.portfolio?.published);

  return isHiddenByAdmin(params?.portfolio);
};

export const getUsernameByEntity = (entity, params) => {
  if (entity === EntitiesType.SECTION) return params?.section?.username;
  if (entity === EntitiesType.ARTICLE) return params?.article?.username;
  return '';
};

export const hasPortfolioDraftByEntity = (entity, params) => {
  if (entity === EntitiesType.SECTION)
    return isDraft(params?.section?.portfolio?.draft?.workflow);

  if (entity === EntitiesType.ARTICLE)
    return isDraft(params?.article?.portfolio?.draft?.workflow);

  return false;
};

export const listRandom = (array) => {
  return array
    ?.map((item) => ({ ...item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort);
};

export const onKeyDownEscape = (event, setOpen) => {
  if (event.key === 'Escape') {
    setOpen(false);
  }
};

export const formatAdmins = (admins) => {
  let output = '';
  for (let admin of admins) {
    output += `${admin.first_name} ${admin.last_name} - ${admin.username}, `;
  }
  return output.slice(0, output.length - 2);
};

export const areObjectArraysEqualByProperty = (array1, array2, property) => {
  if (array1.length === array2.length) {
    for (let i = 0; i < array1.length; i++) {
      if (array1[i]?.[property] !== array2[i]?.[property]) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const formatCoordinatesToArray = (coordinates) => {
  const formattedCordinates = coordinates.split(', ');
  if (formattedCordinates.length === 2) {
    return [Number(formattedCordinates[1]), Number(formattedCordinates[0])];
  }
  return;
};

export const formatCoordinatesToString = (coordinates) => {
  return coordinates[1] + ', ' + coordinates[0];
};

export const formatAddress = (state, country) => {
  return state + ', ' + country;
};

export const formatCoordinates = (asset) => {
  return {
    lat:
      asset?.id_node === '1028' ? 20 : asset?.location?.coordinates?.[1] || 0,
    lng:
      asset?.id_node === '1028' ? 20 : asset?.location?.coordinates?.[0] || 0,
  };
};

export const formatDateEvent = (event) => {
  const eventDate = new Date(event);
  const localeEventDate = new Date(
    eventDate.getTime() - eventDate.getTimezoneOffset() * 1000 * 60
  );
  const localeDateTimeString = localeEventDate.toLocaleString('en-GB');
  return localeDateTimeString.replace(',', '');
};

export const getAssetState = (asset, historic, index) => {
  if (historic) {
    let assetStatus = asset?.asset_status;
    if (assetStatus === 'moving') assetStatus += `_${asset?.direction}`;

    if (index === 0) return assetStatus;
    if (assetStatus.indexOf('moving') !== -1) {
      assetStatus = assetStatus.replace('moving', 'green_arrow');
      return assetStatus;
    }
    if (assetStatus.indexOf('stopped') !== -1) return 'stopped_small';

    return assetStatus;
  }

  let assetStatus = asset?.asset_status;
  if (assetStatus === 'moving') assetStatus += `_${asset?.direction}`;

  return asset?.disconnected ? 'disconnected' : assetStatus;
};
