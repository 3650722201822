import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import { LinkWithRoute } from '../../../../shared/BackOffice/Buttons/BOCommonButtons';
import { SECTOR_COLORS, SECTOR_PAGES } from '../../../../utils/Constants';
import { useHomeStyles } from './useHomeStyles';
import { useTranslation } from 'react-i18next';
import SkipLink from '../../../../shared/Elements/SkipLinks/SkipLink';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function HomeSectors({ sectors }) {
  const classes = useHomeStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.sectorBoxWrapper}>
      <section
        id='sectors'
        aria-label={t('home.sectors.label', {
          size: sectors.length,
        })}
      >
        <SkipLink href={`#portfolios`}>
          {t('home.sectors.skip-to-portfolios')}
        </SkipLink>
        <Container className={classes.sectorContainer}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={8} className={classes.sectorBox}>
              {sectors?.length > 0 &&
                sectors?.map(({ key }) =>
                  Object.keys(SECTOR_COLORS).includes(key) ? (
                    <Fragment key={key}>
                      <LinkWithRoute path={`/sector/${key}`}>
                        <Typography
                          className={classes.sectorName}
                          sx={{
                            '&:hover': {
                              color: SECTOR_COLORS[key],
                            },
                          }}
                        >
                          {SECTOR_PAGES[key]}
                        </Typography>
                      </LinkWithRoute>
                      {SECTOR_PAGES[key] && (
                        <Typography className={classes.sectorDot}>·</Typography>
                      )}
                    </Fragment>
                  ) : null
                )}
            </Grid>
            <Grid item xs={12} md={4} className={classes.moreMica}>
              <Link to={'/que-es-mica'} className={classes.linkAboutMica}>
                <div className={classes.moreMicaText}>
                  {t('home.sectors.more')}
                  <span style={{ position: 'absolute' }}>
                    <ArrowForwardIcon className={classes.arrowForward} />
                  </span>
                </div>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </section>
    </Box>
  );
}
