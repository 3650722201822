import { useContext } from 'react';
import CurrentUserContext from '../context/CurrentUserContext';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import { Backoffice } from '../utils/Constants';

export function useCurrentUserData() {
  const { userData, setUserData, isLoadingTokenUser, setIsLoadingTokenUser } =
    useContext(CurrentUserContext);
  let shouldUpdateInfo = true;

  const shouldGetCurrentUserData = async () => {
    if (isUserLoggedIn() && shouldUpdateInfo) {
      await getCurrentUserData();
    }
  };

  const getCurrentUserData = async () => {
    await UserService.getCurrentUser()
      .then(({ data }) => {
        setUserData(data);
        shouldUpdateInfo = false;
      })
      .catch((error) => {
        console.error(error);
        shouldUpdateInfo = false;
      });
  };

  const isUserLoggedIn = () => {
    return AuthService.isAuthenticated();
  };

  const updateUserData = () => {
    shouldUpdateInfo = true;
    shouldGetCurrentUserData();
  };

  const shouldDisablePortfolioEdit = () => {
    return (
      userData?.portfolio_sent &&
      !userData?.portfolio_published_exist &&
      isPortfolioPendingValidation()
    );
  };

  const isPortfolioPendingValidation = () => {
    return userData?.portfolio_draft?.workflow === Backoffice.States.PENDING;
  };

  const isPortfolioDraft = () => {
    return userData?.portfolio_draft?.workflow === Backoffice.States.DRAFT;
  };

  const isEntityPendingValidation = (workflow) => {
    return workflow === Backoffice.States.PENDING;
  };

  const isEntityDraft = (workflow) => {
    return workflow === Backoffice.States.DRAFT;
  };
  return {
    userData,
    updateUserData,
    isLoadingTokenUser,
    setIsLoadingTokenUser,
    isUserLoggedIn,
    shouldDisablePortfolioEdit,
    isPortfolioPendingValidation,
    isPortfolioDraft,
    isEntityPendingValidation,
    isEntityDraft,
  };
}
