import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Typography, useMediaQuery } from '@mui/material';
import PortfolioCardSector from '../../../../components/Sectors/PortfoliosBySectors/PortfolioCardSector';
import { theme } from '../../../../theme';
import SlickCarrouselInHome from './SlickCarrouselInHome';
import SkipLink from '../../../../shared/Elements/SkipLinks/SkipLink';
import classNames from 'classnames';

const HomePortfolios = ({
  portfolios,
  title,
  classes,
  colors,
  skipTo,
  getSectorDescriptionByKey,
}) => {
  const { t } = useTranslation();
  const viewMore = `/resultados-busqueda/?query=&type=perfil`;
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const slidesToShow = isUpLg ? 6 : isUpMd ? 4 : 2;

  if (!portfolios?.length) {
    return null;
  }
  const getSlidesToShow = () => {
    const countPortfolios = portfolios?.length;
    if (countPortfolios < slidesToShow) return countPortfolios;
    return slidesToShow;
  };

  const portfolioListCards = portfolios?.map((item) => (
    <PortfolioCardSector
      srcVersions={item?.image?.versions}
      title={item?.project_name}
      location={item?.department?.description + ', ' + item?.state?.description}
      id={item?.id_portfolio}
      key={item?.id_portfolio}
      sector={getSectorDescriptionByKey(item?.sector)}
      colors={colors}
    />
  ));

  return (
    <section
      id='portfolios'
      aria-label={t('home.carrousels.portfolios-label', {
        size: portfolios?.length,
      })}
    >
      <Container
        className={classNames(
          classes.containerPortfolios,
          classes.containerPortfoliosHome
        )}
      >
        <div>
          {skipTo && (
            <SkipLink href={`#${skipTo}`}>
              {t(`home.carrousels.skip-to-${skipTo}`)}
            </SkipLink>
          )}
          <div className={classes.portfolioListHome}>
            <SlickCarrouselInHome
              slidesToShow={getSlidesToShow()}
              name={'portfolioListHome'}
              height={'200px'}
            >
              {portfolioListCards}
            </SlickCarrouselInHome>
          </div>
        </div>
        <div className={classes.containerTitle}>
          <Typography
            variant='h5'
            component={'h5'}
            className={classNames(
              classes.titlePortfolioBySector,
              classes.titlePortfolioInHome
            )}
          >
            {title}
          </Typography>
          <div className={classes.containerButton}>
            <Link
              className={classes.button}
              to={viewMore}
              aria-label={
                t('sectors.viewMore.more') +
                ' ' +
                t('home.carrousels.portfolios')
              }
            >
              {t('sectors.viewMore.more')}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomePortfolios;
