import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SkipLink from '../../../../shared/Elements/SkipLinks/SkipLink';

export const HomeCultureMap = ({ isHighlightEnabled }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <section
      id='culture-map'
      aria-label={t('home.carrousels.culture-map-label')}
      style={{ position: 'relative', maxWidth: 1360, margin: 'auto' }}
    >
      <div>
        {isHighlightEnabled ? (
          <SkipLink href={`#highlight`}>
            {t('home.carrousels.skip-to-highlight')}
          </SkipLink>
        ) : (
          <SkipLink href={`#activities`}>
            {t('home.carrousels.skip-to-activities')}
          </SkipLink>
        )}
      </div>
      <Box
        sx={{
          backgroundColor: '#E3313D',
          maxWidth: 1360,
          margin: 'auto',
          mb: 6.375,
          display: 'flex',
          color: '#fff',
          padding: { xs: '37px 32px 37px 32px', md: '37px 107px 37px 89px' },
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: isMobile ? 'wrap' : 'no-wrap',
          gap: isMobile ? 2 : 0,
        }}
      >
        <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
          <img
            height='70px'
            width='55px'
            src={`${process.env.PUBLIC_URL}/icons/big-location.svg`}
            alt='icon'
            style={{ margin: '5px 12.5px' }}
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: isMobile ? '24px' : '30px',
              lineHeight: '33px',
              maxWidth: 340,
              minWidth: 185,
            }}
          >
            {t('home.culture-map.title')}
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: 465,
            marginInline: isMobile ? '12.5px' : { xs: 4, md: 8 },
          }}
        >
          <Typography
            sx={{ fontSize: 16, fontWeight: 500, lineHeight: '19.5px' }}
          >
            {t('home.culture-map.description')}
          </Typography>
        </Box>
        <Link to={'/gps-cultural'} style={{ textDecoration: 'none' }}>
          <Box
            style={{
              paddingInline: 20,
              paddiongBlock: 0,
              borderRadius: '30px',
              border: 'none',
              height: '30px',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
              marginLeft: isMobile ? '12.5px' : 0,
            }}
          >
            <Typography
              sx={{
                fontSize: 13,
                fontWeight: 600,
                textWrap: 'nowrap',
                color: '#262626',
              }}
            >
              {t('home.culture-map.button')}
            </Typography>
          </Box>
        </Link>
      </Box>
    </section>
  );
};
